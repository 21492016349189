import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { MetaMaskInpageProvider } from '@metamask/providers';

import useIsMobile from 'hooks/useIsMobile';
import { selectedScenarioAtom, connectedWalletTypeAtom } from 'states/mainStore';
import { biportExistAtom, coinbaseExistAtom, metamaskExistAtom } from 'states/walletStore';
import { findCoinbase, findMetamask } from 'lib/findProvider';
import { showBalanceAtom } from 'states/mobileStore';
import Loading from 'components/common/loading';
import useAccountChanged from 'hooks/useAccountChanged';
import useConnectWalletPopup from 'hooks/useConnectWalletPopup';
import useConnectWallet from 'hooks/useConnectWallet';
import useWalletTitle from 'hooks/useWalletTitle';
import { ReactComponent as BalanceIcon } from 'assets/balance.svg';
import useConnected from 'hooks/useIsConnected';
import LogoBiport from 'assets/logo-biport.png';
import { ReactComponent as CoinbaseLogo } from 'assets/logo-coinbase.svg';
import { ReactComponent as MetamaskLogo } from 'assets/logo-metamask.svg';
import Logo from './logo';
import Network from './network';
import Balance from './balance';
import Hambuger from './hambuger';
import AddressInnerContainer from './address/addressInnterContainer';
import WalletTitle from './address/walletTitle';
import AddressContainer from './address/addressContainer';
import DisconnectWallet from './disconnectWallet';

const Container = styled.div<{ isMobile: boolean }>`
  width: 100%;
  /* height: ${({ isMobile }) => (isMobile ? '52px' : 'initial')}; */
  padding: ${({ isMobile }) => (isMobile ? '0px' : '30px 12px 12px 12px')};
  background-color: #238fc8;
  border-radius: ${({ isMobile }) => (isMobile ? '0px' : '0 0 12px 12px')};
`;

const HeaderContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  padding: ${({ isMobile }) => (isMobile ? '0px 0px 0px 16px' : '0px')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'row' : 'column')};
  justify-content: ${({ isMobile }) => (isMobile ? 'space-between' : 'start')};
`;

const ConnectWallet = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '300px' : '100%')};
  height: ${({ isMobile }) => (isMobile ? '40px' : 'initial')};
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.25);
  display: flex;
  justify-content: center;
  text-align: center;
  line-height: ${({ isMobile }) => (isMobile ? 'initial' : '36px')};
  margin: ${({ isMobile }) => (isMobile ? '6px 6px 6px 0' : '8px 0 0 0')};
  cursor: pointer;
  align-items: center;
  padding: 0px 6px;
`;

const WalletStatus = () => {
  const [loadingWallet] = useState(false);
  const [expand, setExpand] = useAtom(showBalanceAtom);
  const [selectedScenario] = useAtom(selectedScenarioAtom);
  const [connectedWalletType] = useAtom(connectedWalletTypeAtom);
  const [biportExist] = useAtom(biportExistAtom);
  const [coinbaseExist] = useAtom(coinbaseExistAtom);
  const [metamaskExist] = useAtom(metamaskExistAtom);
  const [showPopup] = useConnectWalletPopup();
  const connected = useConnected();
  const { t } = useTranslation();
  const address = useWalletTitle('');

  const isMobile = useIsMobile();

  const connectBiportWallet = useConnectWallet('evaluator.connectBiport', 'biport');
  const connectCoinbaseWallet = useConnectWallet('evaluator.connectCoinbase', 'coinbase');
  const connectMetamaskWallet = useConnectWallet('evaluator.connectMetamask', 'metamask');

  const biportAccountChanged = useAccountChanged('evaluator.connectBiport', 'biport');
  const coinbaseAccountChanged = useAccountChanged('evaluator.connectCoinbase', 'coinbase');
  const metamaskAccountChanged = useAccountChanged('evaluator.connectMetamask', 'metamask');

  const WalletIcon = () => {
    switch (connectedWalletType) {
      case 'biport':
        return <img src={LogoBiport} alt="" />;
      case 'coinbase':
        return <CoinbaseLogo style={{ maxWidth: '27px', maxHeight: '27px' }} />;
      case 'metamask':
        return <MetamaskLogo />;
      default:
        return <></>;
    }
  };

  const connectWalletClick = useCallback(() => {
    let deviceIsMobile = false;
    // At the beginning we set this flag as false. If we can detect the device is a mobile device in the next line, then we set it as true.

    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        navigator.userAgent,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        navigator.userAgent.substr(0, 4),
      )
    ) {
      deviceIsMobile = true;
    }

    if (deviceIsMobile) {
      if (biportExist) connectBiportWallet();
      else if (coinbaseExist) connectCoinbaseWallet();
      else if (metamaskExist) connectMetamaskWallet();
      else showPopup();
    } else {
      showPopup();
    }
  }, [biportExist, coinbaseExist, metamaskExist]);

  useEffect(() => {
    const { biport } = window;
    const coinbase = findCoinbase();
    const metamask = findMetamask();

    biport?.on('accountsChanged', biportAccountChanged);
    coinbase?.on('accountsChanged', coinbaseAccountChanged);
    metamask?.on('accountsChanged', metamaskAccountChanged);

    return () => {
      biport?.removeListener('accountsChanged', biportAccountChanged);
      coinbase?.removeListener('accountsChanged', coinbaseAccountChanged);
      metamask?.removeListener('accountsChanged', metamaskAccountChanged);
    };
  }, []);

  useEffect(() => {
    const lastConnectedWalletType = localStorage.getItem('lastConnectedWalletType');

    switch (lastConnectedWalletType) {
      case 'biport':
        if (biportExist) connectBiportWallet();
        break;
      case 'coinbase':
        if (coinbaseExist) connectCoinbaseWallet();
        break;
      case 'metamask':
        if (metamaskExist) connectMetamaskWallet();
        break;
      default:
        break;
    }
  }, [biportExist, coinbaseExist, metamaskExist]);

  return (
    <Container isMobile={isMobile}>
      <HeaderContainer isMobile={isMobile}>
        <Hambuger />
        <Logo />
        {isMobile && selectedScenario && (
          <BalanceIcon
            style={{
              minWidth: '40px',
              height: '40px',
              padding: '6px',
              background: 'rgba(255, 255, 255, 0.1)',
              borderRadius: '12px',
              margin: '6px',
            }}
            onClick={() => setExpand(!expand)}
          />
        )}
        <Network />
        {loadingWallet && <Loading style={{ margin: '0 auto' }} />}
        {!connected && (
          <ConnectWallet isMobile={isMobile} onClick={connectWalletClick}>
            {t('walletStatus.connectWallet')}
          </ConnectWallet>
        )}
        {connected && (
          <AddressContainer isMobile={isMobile}>
            <AddressInnerContainer>
              {WalletIcon()}
              <WalletTitle>{address}</WalletTitle>
            </AddressInnerContainer>
            <DisconnectWallet onClick={() => undefined} />
          </AddressContainer>
        )}
      </HeaderContainer>
      <Balance />
    </Container>
  );
};

export default WalletStatus;
