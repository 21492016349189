import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import dompurify from 'dompurify';
import { useNavigate } from 'react-router-dom';

import useIsMobile from 'hooks/useIsMobile';
import { languageAtom } from 'states/mainStore';
import Color from 'styles/colors';
import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { ReactComponent as YoutubeIcon } from 'assets/youtube.svg';
import AboutImage1 from 'assets/about-image-1.png';
import AboutImage2 from 'assets/about-image-2.png';
import Footer from '../footer';

const HorizonContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ isMobile }) => (isMobile ? '20px' : 'inherit')};
`;

const Container = styled.div<{ isMobile: boolean }>`
  position: absolute;
  top: 0px;
  min-width: ${({ isMobile }) => (isMobile ? 'inherit' : '1092px')};
  width: ${({ isMobile }) => (isMobile ? '100vw' : '65vw')};
  height: 100vh;
  background-color: #121b1f;
  border: solid 1px ${Color.Border};
  border-left: 0;
  padding: ${({ isMobile }) => (isMobile ? '20px' : '66px')};
  overflow-y: scroll;
`;

const Minus = styled.div`
  width: 31px;
  height: 5px;
  background-color: #00d6ff;
`;

const H1 = styled.div<{ isMobile: boolean }>`
  font-size: ${({ isMobile }) => (isMobile ? '22px' : '32px')};
  font-weight: bold;
  white-space: pre-wrap;
`;

const H2 = styled.div<{ isMobile: boolean }>`
  font-size: ${({ isMobile }) => (isMobile ? '18px' : '24px')};
  font-weight: bold;
  white-space: pre-wrap;
`;

const P = styled.div<{ isMobile: boolean }>`
  width: 100%;
  margin: 20px 0;
  padding: ${({ isMobile }) => (isMobile ? '20px' : '35px')};
  margin-bottom: ${({ isMobile }) => (isMobile ? '50px' : '70px')};
  border-radius: 30px;
  background-color: #0e161e;
  white-space: pre-wrap;
  line-height: 1.7rem;
  font-size: ${({ isMobile }) => (isMobile ? '15px' : '18px')};
`;

const LearnMore = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00d6ff;
  font-size: 18px;
  font-weight: bold;
  border: 3px solid #00d6ff;
  box-shadow: 0px 0px 14px #00d6ff73;
  border-radius: 100px;
  text-align: center;
  width: 260px;
  height: 80px;
  margin: 0 auto;
  cursor: pointer;
  text-decoration: none;
  white-space: pre-wrap;
`;

const About = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [language] = useAtom(languageAtom);
  const sanitizer = dompurify.sanitize;
  const isMobile = useIsMobile();

  const technicalReportUrl = useMemo(() => {
    if (language === 'kr') {
      return 'https://bifrost.medium.com/a430763a5e2c?source=friends_link&sk=8cad776493f0a4956f7f33ff0dc73ad5';
    }

    if (language === 'en') {
      return 'https://bifrost.medium.com/f6aaa98bd17b?source=friends_link&sk=70b4b8e8b6a0c390ee1decf85d284d2c';
    }

    return '';
  }, [language]);

  const introductionUrl = useMemo(() => {
    if (language === 'kr') {
      return 'https://medium.com/bifrost-blog-kor/chainrunner%EB%8A%94-%EB%AC%B4%EC%97%87%EC%9D%B8%EA%B0%80%EC%9A%94-45d36e0fd221';
    }

    if (language === 'en') {
      return 'https://medium.com/bifrost/what-is-chainrunner-bbe81422fc16';
    }

    return '';
  }, [language]);

  const documentationUrl = useMemo(() => {
    if (language === 'kr') {
      return 'https://docs.thebifrost.io/chainrunner/';
    }

    if (language === 'en') {
      return 'https://docs.thebifrost.io/chainrunner-q/';
    }

    return '';
  }, [language]);

  const youtubeGuideUrl = useMemo(() => {
    if (language === 'kr') {
      return 'https://www.youtube.com/watch?v=7Qe4wgfbNfA&list=PL2vuJd1qLwtAsbBnM4TE3Xgjh0KkG4FGE';
    }

    if (language === 'en') {
      return 'https://www.youtube.com/watch?v=h6m4x-CnMV8&list=PL2vuJd1qLwtCGDx--1sBTSc7BiNApEGc3';
    }

    return '';
  }, [language]);

  // if (!showAboutPage) return <></>;

  return (
    <Container isMobile={isMobile}>
      <HorizonContainer isMobile={isMobile} style={{ justifyContent: 'end' }}>
        <CloseIcon
          style={{ width: '32px', height: '32px', cursor: 'pointer' }}
          onClick={() => navigate('/home')}
        />
      </HorizonContainer>
      <HorizonContainer isMobile={isMobile} style={{ marginBottom: '64px', marginTop: '48px' }}>
        <H1 isMobile={isMobile}>{t('about.title')}</H1>
      </HorizonContainer>
      <P isMobile={isMobile}>{t('about.subtitle')}</P>
      {language === 'en' && (
        <>
          <HorizonContainer isMobile={isMobile}>
            <Minus />
            <H2 isMobile={isMobile} style={{ marginLeft: '14px' }}>
              {t('about.eq1')}
            </H2>
          </HorizonContainer>
          <P isMobile={isMobile}>{t('about.ea1')}</P>
        </>
      )}
      <HorizonContainer isMobile={isMobile}>
        <Minus />
        <H2 isMobile={isMobile} style={{ marginLeft: '14px' }}>
          {t('about.q1')}
        </H2>
      </HorizonContainer>
      <P isMobile={isMobile}>{t('about.a1')}</P>
      <HorizonContainer isMobile={isMobile}>
        <Minus />
        <H2 isMobile={isMobile} style={{ marginLeft: '14px' }}>
          {t('about.q2')}
        </H2>
      </HorizonContainer>
      <P isMobile={isMobile}>
        <img src={AboutImage1} alt="" style={{ maxWidth: '100%' }} />
      </P>
      <P isMobile={isMobile}>{t('about.a2')}</P>
      <HorizonContainer isMobile={isMobile}>
        <Minus />
        <H2 isMobile={isMobile} style={{ marginLeft: '14px' }}>
          {t('about.q3')}
        </H2>
      </HorizonContainer>
      <P isMobile={isMobile}>
        <div>{t('about.a3.p1')}</div>
        <ul>
          <li>{t('about.a3.li1')}</li>
          <li>{t('about.a3.li2')}</li>
          <li>{t('about.a3.li3')}</li>
          <li>{t('about.a3.li4')}</li>
          <li>{t('about.a3.li5')}</li>
        </ul>
        <div>{t('about.a3.p2')}</div>
        {language === 'kr' && (
          <ol>
            <li dangerouslySetInnerHTML={{ __html: sanitizer(t('about.a3.li6')) }} />
            <li
              dangerouslySetInnerHTML={{ __html: sanitizer(t('about.a3.li7')) }}
              style={{ marginTop: '14px' }}
            />
          </ol>
        )}
      </P>
      <HorizonContainer isMobile={isMobile}>
        <Minus />
        <H2 isMobile={isMobile} style={{ marginLeft: '14px' }}>
          {t('about.q4')}
        </H2>
      </HorizonContainer>
      <P isMobile={isMobile}>
        <img src={AboutImage2} alt="" style={{ maxWidth: '100%' }} />
      </P>
      <P isMobile={isMobile}>{t('about.a4')}</P>
      <HorizonContainer isMobile={isMobile}>
        <Minus />
        <H2 isMobile={isMobile} style={{ marginLeft: '14px' }}>
          {t('about.q5')}
        </H2>
      </HorizonContainer>
      <P isMobile={isMobile}>
        {language === 'en' && (
          <>
            <div>{t('about.a5.p1')}</div>
            <ul>
              <li>{t('about.a5.li1')}</li>
              <li>{t('about.a5.li2')}</li>
            </ul>
            <div>{t('about.a5.p2')}</div>
          </>
        )}
        {language === 'kr' && <div>{t('about.a5')}</div>}
      </P>
      <H2
        isMobile={isMobile}
        style={{ textAlign: 'center', margin: '40px 0' }}
        dangerouslySetInnerHTML={{ __html: sanitizer(t('about.learnMore')) }}
      />
      <HorizonContainer
        isMobile={isMobile}
        style={{ justifyContent: 'space-between', flexDirection: isMobile ? 'column' : 'row' }}>
        <LearnMore target="_blank" href={technicalReportUrl}>
          Chainrunner
          <br />
          Technical Report
        </LearnMore>
        <LearnMore target="_blank" href={introductionUrl}>
          Chainrunner
          <br />
          Introduction
        </LearnMore>
        <LearnMore target="_blank" href={documentationUrl}>
          Chainrunner
          <br />
          Documentation
        </LearnMore>
      </HorizonContainer>
      <LearnMore
        target="_blank"
        href={youtubeGuideUrl}
        style={{
          color: '#FF0000',
          border: '3px solid #FF0000',
          boxShadow: '0px 0px 14px #FF0000',
          margin: '44px auto',
          display: 'flex',
        }}>
        <YoutubeIcon style={{ width: '32px', height: '32px', marginRight: '12px' }} />
        <div>Video Guide</div>
      </LearnMore>
      <div style={{ height: '44px' }} />
      <Footer />
    </Container>
  );
};

export default About;
