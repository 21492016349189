import EvaluatorResponse from 'types/evaluatorResponse';

let retry = 0;

const invokeMethodAsync = async <T = unknown>(
  assemblyName: string,
  methodIdentifier: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...params: any[]
): Promise<EvaluatorResponse & T> => {
  try {
    console.log(methodIdentifier);
    const res = await window.DotNet.invokeMethodAsync<string>(
      assemblyName,
      methodIdentifier,
      ...params,
    );
    const jsonRes = JSON.parse(res);

    retry = 0;

    return jsonRes as EvaluatorResponse & T;
  } catch (e) {
    console.log(methodIdentifier, retry, e);
    if (retry > 10) {
      return {
        Ok: false,
        Error: 'Exceed call limit 10',
      } as EvaluatorResponse & T;
    }

    await new Promise((resolve) => setTimeout(resolve, 500));

    retry += 1;

    return await Promise.resolve(invokeMethodAsync(assemblyName, methodIdentifier, ...params));
  }
};

export default invokeMethodAsync;
