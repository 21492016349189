import React from 'react';
import { useAtom } from 'jotai';

import useIsMobile from 'hooks/useIsMobile';
import { loadingWalletAtom, metamaskExistAtom } from 'states/walletStore';
import { ReactComponent as MetamaskLogo } from 'assets/logo-metamask.svg';
import ConnectWalletProps from 'types/connectWalletProps';
import useConnectWallet from 'hooks/useConnectWallet';
import useWalletTitle from 'hooks/useWalletTitle';
import useInstallWalletPopup from 'hooks/useInstallWalletPopup';
import AddressInnerContainer from './address/addressInnterContainer';
import WalletTitle from './address/walletTitle';
import ConnectContainer from './connect/connectContainer';

const ConnectMetamaskButton: React.FC<ConnectWalletProps> = ({ hidePopup }) => {
  const isMobile = useIsMobile();
  const [metamaskExist] = useAtom(metamaskExistAtom);
  const [loadingWallet] = useAtom(loadingWalletAtom);
  const metamaskTitle = useWalletTitle('Metamask');
  const [showInstallWalletPopup] = useInstallWalletPopup(
    'https://s3.ap-northeast-2.amazonaws.com/bifi.finance/qr/metamask_app_bifi.png',
    'https://metamask.app.link/dapp/q.chainrunner.io',
  );

  const connectWallet = useConnectWallet('evaluator.connectMetamask', 'metamask');

  const connectMetamaskClick: React.MouseEventHandler<HTMLElement> = async () => {
    if (metamaskExist) {
      connectWallet();
      hidePopup();
    } else {
      showInstallWalletPopup();
    }
  };

  if (loadingWallet) return <></>;

  return (
    <>
      <ConnectContainer isMobile={isMobile} onClick={connectMetamaskClick}>
        <AddressInnerContainer>
          <MetamaskLogo />
          <WalletTitle>{metamaskTitle}</WalletTitle>
        </AddressInnerContainer>
      </ConnectContainer>
    </>
  );
};

export default ConnectMetamaskButton;
