import { atom } from 'jotai';

const loadingWalletAtom = atom(false);

const biportExistAtom = atom(false);
biportExistAtom.onMount = (setAtom) => {
  const { biport } = window;

  setAtom(biport !== undefined);
};

const coinbaseExistAtom = atom(false);
coinbaseExistAtom.onMount = (setAtom) => {
  const { ethereum } = window;

  if (ethereum?.providers) {
    const provider = ethereum.providers.find((p) => p.isCoinbaseWallet);
    setAtom(provider !== undefined);
  } else {
    setAtom(ethereum && (ethereum.isCoinbaseWallet ?? false));
  }
};

const metamaskExistAtom = atom(false);
metamaskExistAtom.onMount = (setAtom) => {
  const { ethereum } = window;

  if (ethereum?.providers) {
    const provider = ethereum.providers.find((p) => p.isMetaMask);
    setAtom(provider !== undefined);
  } else {
    setAtom(ethereum && ethereum.isMetaMask);
  }
};

export { loadingWalletAtom, biportExistAtom, coinbaseExistAtom, metamaskExistAtom };
