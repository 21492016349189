import React from 'react';
import { useAtom } from 'jotai';

import useIsMobile from 'hooks/useIsMobile';
import { loadingWalletAtom, coinbaseExistAtom } from 'states/walletStore';
import { ReactComponent as CoinbaseLogo } from 'assets/logo-coinbase.svg';
import useConnectWallet from 'hooks/useConnectWallet';
import useInstallWalletPopup from 'hooks/useInstallWalletPopup';
import useWalletTitle from 'hooks/useWalletTitle';
import ConnectWalletProps from 'types/connectWalletProps';
import AddressInnerContainer from './address/addressInnterContainer';
import WalletTitle from './address/walletTitle';
import ConnectContainer from './connect/connectContainer';

const ConnectCoinbaseButton: React.FC<ConnectWalletProps> = ({ hidePopup }) => {
  const isMobile = useIsMobile();
  const [coinbaseExist] = useAtom(coinbaseExistAtom);
  const [loadingWallet] = useAtom(loadingWalletAtom);
  const coinbaseTitle = useWalletTitle('Coinbase Wallet');
  const [showInstallWalletPopup] = useInstallWalletPopup(
    'https://s3.ap-northeast-2.amazonaws.com/bifi.finance/qr/coinbase_app_bifi.png',
    'https://coinbase-wallet.onelink.me/q5Sx/fdb9b250',
  );

  const connectWallet = useConnectWallet('evaluator.connectCoinbase', 'coinbase');

  const connectCoinbaseClick: React.MouseEventHandler<HTMLElement> = async () => {
    if (coinbaseExist) {
      connectWallet();
      hidePopup();
    } else {
      showInstallWalletPopup();
    }
  };

  if (loadingWallet) return <></>;

  return (
    <>
      <ConnectContainer isMobile={isMobile} onClick={connectCoinbaseClick}>
        <AddressInnerContainer>
          <CoinbaseLogo style={{ maxWidth: '27px', maxHeight: '27px' }} />
          <WalletTitle>{coinbaseTitle}</WalletTitle>
        </AddressInnerContainer>
      </ConnectContainer>
    </>
  );
};

export default ConnectCoinbaseButton;
