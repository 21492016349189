/* eslint-disable jsx-a11y/media-has-caption */
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import dompurify from 'dompurify';

import useIsMobile from 'hooks/useIsMobile';
import { categoriesAtom, networkAtom, selectedCategoryAtom } from 'states/mainStore';
import chainrunnerGlitch from 'assets/chainrunner-glitch.mp4';
import arrow from 'assets/arrow-down.png';
import popular1 from 'assets/popular1.png';
import popular2 from 'assets/popular2.png';
import popular3 from 'assets/popular3.png';
import pros1 from 'assets/pros1.png';
import pros2 from 'assets/pros2.png';
import pros3 from 'assets/pros3.png';
import Color from 'styles/colors';
import Footer from '../footer';
import CollapseList, { ICollapseListItem } from './collapseList';

const Container = styled.div<{ isMobile: boolean }>`
  width: 100%;
  height: ${({ isMobile }) => (isMobile ? 'calc(100vh - 36px)' : '100vh')};
  border: solid 1px ${Color.Border};
  border-left: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
`;

const Title = styled.div<{ isMobile: boolean }>`
  font-size: ${({ isMobile }) => (isMobile ? '22px' : '30px')};
  font-weight: bold;
  text-align: center;
`;

const Subtitle = styled.div<{ isMobile: boolean }>`
  font-size: ${({ isMobile }) => (isMobile ? '16px' : '20px')};
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  margin-top: 45px;
`;

const ProsContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column-reverse' : 'row')};
  justify-content: space-around;
  align-items: center;
  margin-top: 40px;

  & > img {
    max-height: ${({ isMobile }) => (isMobile ? '150px' : 'inherit')};
    margin-bottom: ${({ isMobile }) => (isMobile ? '20px' : 'inherit')};
  }
`;

const ProsTitleContainer = styled.div<{ isMobile: boolean }>`
  text-align: ${({ isMobile }) => (isMobile ? 'center' : 'start')};
`;

const Pros = styled.div`
  font-size: 22px;
  font-weight: bold;
  color: #afd7ff;
`;

const ProsSubtitle = styled.div`
  opacity: 0.5;
  margin-top: 35px;
`;

const PopularScenarios = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  justify-content: space-between;
  margin-top: 60px;
  align-items: ${({ isMobile }) => (isMobile ? 'center' : 'inherit')};
`;

const PopularScenarioContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 265px;
  border-radius: 10px;
  background-color: #141d26;

  & > img {
    object-fit: contain;
    width: ${({ isMobile }) => (isMobile ? '150px' : '200px')};
    height: ${({ isMobile }) => (isMobile ? '150px' : '200px')};
  }
`;

const PopularScenarioTitle = styled.div`
  width: 192px;
  height: 51px;
  line-height: 51px;
  border-radius: 25.5px;
  background-color: #00d6ff;
  font-size: 20px;
  font-weight: bold;
  color: #141d26;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
  cursor: pointer;
`;

const Landing: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [categories] = useAtom(categoriesAtom);
  const [, setSelectedClass] = useAtom(selectedCategoryAtom);
  const sanitizer = dompurify.sanitize;

  const [network] = useAtom(networkAtom);

  const isBSC = useMemo(() => network?.chainId === 56, [network]);

  const isMobile = useIsMobile();

  const items: ICollapseListItem[] = [
    {
      title: t('landing.faq.q1'),
      contents: t('landing.faq.a1'),
    },
    {
      title: t('landing.faq.q2'),
      contents: t('landing.faq.a2'),
    },
    {
      title: t('landing.faq.q3'),
      contents: t('landing.faq.a3'),
    },
    {
      title: t('landing.faq.q4'),
      contents: t('landing.faq.a4'),
    },
    {
      title: t('landing.faq.q5'),
      contents: t('landing.faq.a5'),
    },
    {
      title: t('landing.faq.q6'),
      contents: <div dangerouslySetInnerHTML={{ __html: sanitizer(t('landing.faq.a6')) }} />,
    },
  ];

  return (
    <Container isMobile={isMobile}>
      <video autoPlay muted playsInline style={{ maxWidth: '100vw' }}>
        <source src={chainrunnerGlitch} type="video/mp4" />
      </video>
      {/* <video src={chainrunnerGlitch} autoPlay /> */}
      <div
        style={{
          width: '100%',
          padding: isMobile ? '0 20px' : '0 52px',
          textAlign: 'center',
        }}>
        <Title isMobile={isMobile} style={{ marginTop: '45px' }}>
          {t('landing.title')}
        </Title>
        <Subtitle isMobile={isMobile}>{t('landing.subtitle')}</Subtitle>
        <PopularScenarioTitle
          style={{
            cursor: 'pointer',
            margin: '40px auto 20px auto',
            width: '260px',
          }}
          onClick={() => navigate('/about')}>
          {t('landing.about')}
        </PopularScenarioTitle>
        <img style={{ margin: '96px 0', objectFit: 'contain' }} src={arrow} alt="" />
        <Title isMobile={isMobile}>{t('landing.popularScenarios.title')}</Title>
        <Subtitle isMobile={isMobile}>{t('landing.popularScenarios.subtitle')}</Subtitle>
        <PopularScenarios isMobile={isMobile}>
          <PopularScenarioContainer isMobile={isMobile}>
            <img src={popular1} alt="" />
            <PopularScenarioTitle
              onClick={() => {
                if (!isBSC) setSelectedClass(categories[0]);
              }}>
              {t('landing.popularScenarios.migration')}
            </PopularScenarioTitle>
          </PopularScenarioContainer>
          <PopularScenarioContainer isMobile={isMobile}>
            <img src={popular2} alt="" />
            <PopularScenarioTitle
              onClick={() => {
                if (isBSC) setSelectedClass(categories[0]);
                else setSelectedClass(categories[1]);
              }}>
              {t('landing.popularScenarios.bifiX')}
            </PopularScenarioTitle>
          </PopularScenarioContainer>
          <PopularScenarioContainer isMobile={isMobile}>
            <img src={popular3} alt="" />
            <PopularScenarioTitle
              onClick={() => {
                if (isBSC) setSelectedClass(categories[2]);
                else setSelectedClass(categories[3]);
              }}>
              {t('landing.popularScenarios.tokenPurchase')}
            </PopularScenarioTitle>
          </PopularScenarioContainer>
        </PopularScenarios>
        <ProsContainer isMobile={isMobile} style={{ marginTop: '180px' }}>
          <ProsTitleContainer isMobile={isMobile}>
            <Pros>{t('landing.pros.pros1.title')}</Pros>
            <ProsSubtitle>{t('landing.pros.pros1.subtitle')}</ProsSubtitle>
          </ProsTitleContainer>
          <img src={pros1} alt="" />
        </ProsContainer>
        <ProsContainer isMobile={isMobile} style={{ flexDirection: isMobile ? 'column' : 'row' }}>
          <img src={pros2} alt="" />
          <ProsTitleContainer
            isMobile={isMobile}
            style={{ textAlign: isMobile ? 'center' : 'end' }}>
            <Pros>{t('landing.pros.pros2.title')}</Pros>
            <ProsSubtitle>{t('landing.pros.pros2.subtitle')}</ProsSubtitle>
          </ProsTitleContainer>
        </ProsContainer>
        <ProsContainer isMobile={isMobile}>
          <ProsTitleContainer isMobile={isMobile}>
            <Pros>{t('landing.pros.pros3.title')}</Pros>
            <ProsSubtitle>{t('landing.pros.pros3.subtitle')}</ProsSubtitle>
          </ProsTitleContainer>
          <img src={pros3} alt="" />
        </ProsContainer>
        <Title isMobile={isMobile} style={{ paddingBottom: '48px', paddingTop: '180px' }}>
          {t('landing.faq.title')}
        </Title>
        <CollapseList items={items} />
        <div style={{ height: '48px', width: '100%' }} />
        <Footer />
      </div>
    </Container>
  );
};

export default Landing;
