import { atom } from 'jotai';
import StepState from 'types/stepState';

export default class StepStore {
  public stepStateAtom = atom<StepState>('preparing');

  public codeAtom = atom('');

  public verifyCodeAtom = atom('');

  public codeOutputAtom = atom('');

  public codeErrorAtom = atom('');
}
