import React from 'react';
import styled from 'styled-components';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import useIsMobile from 'hooks/useIsMobile';
import { networkAtom, walletAddressAtom } from 'states/mainStore';

const NetworkContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 36px;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.1);
  align-items: center;
  padding: 0 10px;
  margin-top: 16px;
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 10px;
  background-color: greenyellow;
`;

const Network = () => {
  const { t } = useTranslation();
  const [network] = useAtom(networkAtom);
  const [walletAddress] = useAtom(walletAddressAtom);

  const isMobile = useIsMobile();

  if (isMobile) return <></>;

  if (network === undefined) {
    if (walletAddress) {
      <NetworkContainer>
        <Dot style={{ background: '#fa6400' }} />
        <div>{t('walletStatus.unsupportedNetwork')}</div>
      </NetworkContainer>;
    }

    return <></>;
  }

  return (
    <NetworkContainer>
      <Dot />
      <div>{network?.name}</div>
    </NetworkContainer>
  );
};

export default Network;
