import Networks from './networks';

const getNetworkInfoByChainId = (chainId: number) => {
  return Networks.find((x) => x.chainId === chainId);
};

const getNetworkInfoByName = (name: string) => {
  return Networks.find((x) => x.name === name || x.name.includes(name));
};

export { getNetworkInfoByChainId, getNetworkInfoByName };
