import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { useAtom } from 'jotai';

import { showBalanceAtom } from 'states/mobileStore';
import { assetsAtom, networkAtom, walletAddressAtom, selectedScenarioAtom } from 'states/mainStore';
import Loading from 'components/common/loading';
import getTokenInfoBySymbol from 'lib/getTokenInfoBySymbol';
import { SmallIconStyle } from 'styles/iconStyle';
import invokeMethodAsync from 'lib/invokeMethodAsync';
import loadAssets from 'lib/loadAssets';
import useIsMobile from 'hooks/useIsMobile';

const BalanceContainer = styled.div<{ isMobile: boolean }>`
  background-color: rgba(255, 255, 255, 0.35);
  border-radius: 12px;
  margin-top: 8px;
  border-radius: ${({ isMobile }) => (isMobile ? '0 0 12px 12px' : '12px')};
  margin-top: ${({ isMobile }) => (isMobile ? '0px' : '8px')};
`;

const BalanceRow = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  padding: 10px 6px;
`;

const Balance = () => {
  const [network] = useAtom(networkAtom);
  const [walletAddress] = useAtom(walletAddressAtom);
  const [assets, setAssets] = useAtom(assetsAtom);
  const [selectedScenario] = useAtom(selectedScenarioAtom);
  const [showBalance] = useAtom(showBalanceAtom);
  const [loadingAsset, setLoadingAsset] = useState(false);

  const isMobile = useIsMobile();

  const connected = useMemo(() => {
    return walletAddress?.length > 0;
  }, [walletAddress]);

  useEffect(() => {
    if (network === undefined || selectedScenario === undefined || walletAddress === '') {
      setAssets([]);
      return;
    }

    (async () => {
      setLoadingAsset(true);
      await invokeMethodAsync('ToGoEvaluator', 'evaluator.updateChain');
      const newAssets = await loadAssets(selectedScenario.Metadata.Currency);
      setAssets(newAssets);
      setLoadingAsset(false);
    })();
  }, [selectedScenario, walletAddress, network]);

  return (
    <>
      {loadingAsset && <Loading style={{ margin: '0 auto' }} />}
      {showBalance && !loadingAsset && connected && (
        <BalanceContainer isMobile={isMobile}>
          {assets &&
            assets.length > 0 &&
            assets.map((asset) => {
              let symbol = asset.Name;
              if (symbol.toUpperCase() === 'BIFI' || symbol.toUpperCase() === 'BIFIB')
                symbol = 'BIFIT';

              const token = getTokenInfoBySymbol(symbol);

              return (
                <BalanceRow key={asset.Name}>
                  <div style={{ marginRight: '4px' }}>{asset.Value}</div>
                  <img src={token.icon} alt="" style={SmallIconStyle} />
                </BalanceRow>
              );
            })}
        </BalanceContainer>
      )}
    </>
  );
};

export default Balance;
