import React from 'react';

const DisableButtonStyle = (enabled: () => boolean): React.CSSProperties => {
  if (!enabled()) {
    return {
      opacity: '0.5',
      pointerEvents: 'none',
      transition: 'opacity 0.15s ease',
    };
  }

  return {
    transition: 'opacity 0.15s ease',
  };
};

export default DisableButtonStyle;
