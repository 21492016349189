const resource = {
  translation: {
    sitemap: {
      showAllMenus: '시나리오 전체보기',
    },
    walletStatus: {
      connectWallet: '지갑 연결',
      unsupportedNetwork: '지원하지 않는 네트워크',
    },
    popup: {
      noWallet: {
        message: '월렛이 설치되어있지 않습니다.',
      },
      connectWallet: {
        title: '연결할 지갑을 선택하세요.\n지갑이 없는 경우, 먼저 지갑을 설치하세요.',
      },
      noConnectedWallet: {
        message: 'Metamask / Biport / Coinbase 지갑에 먼저 연결해주세요',
      },
      reset: {
        message:
          '연결된 지갑의 주소 혹은 네트워크 변경으로 인해 현재까지 진행된 과정이 초기화 되었습니다',
      },
      account: {
        message: '계정이 변경되어 페이지를 새로고침합니다.',
      },
      klaytn: {
        title: '- 주의 -',
        message:
          '일부 유저의 경우 Cypress 연결이 제대로 동작하지 않을 수 있습니다. 만약 그렇다면 아래\n정보를 확인하여 메타마스크에서 커스텀 RPC를 다시 등록하여주시기 바랍니다.',
        name: '네트워크 이름',
        rpc: '새 RPC URL',
        chainId: '체인 ID',
        symbol: '통화 기호 (선택 사항)',
        explore: '블록 탐색기 URL (선택 사항)',
      },
      confirm: '확인',
    },
    tab: {
      about: 'ChainRunner란?',
    },
    landing: {
      title: '복잡한 디파이, 한번에 해결하세요.',
      subtitle: '너무 많아서 어렵고 번거로운 디파이. 이제 ChainRunner Q로 한번에 처리하세요.',
      about: 'ChainRunner란?',
      popularScenarios: {
        title: 'Popular Scenarios',
        subtitle:
          'ETH, BSC, 그리고 더 많은 블록체인을 연결하는 ChainRunner Q의 강력한 기능을 지금 활용해보세요.',
        migration: '자산 이동하기',
        bifiX: 'BiFi X',
        bifiXDescription: '블록체인의 복잡한 여러 서비스를 한번에 수행하세요.',
        tokenPurchase: '토큰 구매하기',
      },
      pros: {
        pros1: {
          title: '여러 디파이, smart contract를 한곳에.',
          subtitle:
            '시간을 들여 배워야 할 블록체인 플랫폼은 너무 많습니다.\n이제 ChainRunner Q 로 한번에 처리하세요',
        },
        pros2: {
          title: '복잡하고 번거로운 서비스들을 한번에.',
          subtitle: '블록체인의 복잡한 여러 서비스를 한번에 수행하세요.',
        },
        pros3: {
          title: '그 어떤 서비스보다 더 세밀하고, 정확하게 블록체인을 활용하세요.',
          subtitle:
            '뿐만 아니라, 내 입맛에 맞는 새로운 서비스를 원하는대로 추가하고 ChainRunner의 지능을 직접 높여 보세요!',
        },
      },
      faq: {
        title: 'FAQ',
        q1: 'Chainrunner가 무엇인가요?',
        a1: 'ChainRunner는 블록체인 생태계에서 서비스끼리 쉽게 연결되고, 어려운 블록체인을 사용자가 쉽게 사용할 수 있게 하기 위해 개발되었습니다. 사용자 입장에서는 수많은 블록체인 서비스들을 쉽게 이용할 수 있어야 하고, 반대로 플랫폼에서는 쉽고 빠르게 다양한 서비스를 제공하여 더 많은 사용자한테 도달할 수 있어야 하죠. 그래서 ChainRunner는 모든 곳에서 도움을 주는 엔진 형태로 발전하였습니다. ChainRunner 엔진은 사용자에게는 여러 서비스를 한번에 엮어 복잡한 기능을 대신 수행해주고, 서비스의 곁에서는 상황에 따라 다른 서비스들과 연계한 기능들을 바로 추가해주는, 서비스의 지능을 높이는 역할을 합니다. ChainRunner는 앞으로 여러가지의 버전으로 발전합니다. 그중, "Q"는 ChainRunner가 당장 사용자 곁에서 모든 일을 쉽게 해주는 서비스입니다. 여러분의 컴퓨터 브라우저 위에서 각종 DeFi의 사용법을 요리책처럼 가지고, 블록체인의 복잡한 여러 서비스를 한번에 처리해 줍니다. 그리고 추후, 더 업그레이드된 ChainRunner Q에서는 여러분이 직접 불편하거나 입맛에 맞는 새로운 서비스를 원하는대로 추가하여, ChainRunner의 지능을 직접! 높일 수 있습니다!',
        q2: '왜 ChainRunner Q를 사용해야 할까요?',
        a2: '1. 어려운 디파이, ChainRunner Q로 쉽고 빠르게 관리하세요.\n\n그동안 디파이가 너무 어렵고 복잡하여 시도해보지도 못했다면, ChainRunner Q를 사용해보시는걸 추천드립니다. 블록체인 생태계에서 디파이, 메인넷 등의 종류는 너무 많고, 복잡하고, 배워야 할 절차들이 너무 많습니다. 나의 자산을 디파이끼리 옮기는 과정만 해도 각 디파이의 사용법을 일일이 익혀야 하는 상황이죠. ChainRunner Q에서는 다양한 디파이에서 발생하는 다양한 서비스 시나리오가 한번에 제공되기 때문에, 정해진 메뉴얼대로 거래에 사용될 가상자산의 수량만 입력하면 한번에 다양한 거래를 처리할 수 있습니다.\n\n2. ChainRunner Q로 더 자유롭고 정확하게 smart contract를 활용하세요.\n\n또한 ChainRunner Q는 아주 세밀한 자동화가 가능합니다. DeFi 서비스의 자동화를 표방하는 서비스들이 있지만, ChainRunner Q는 훨씬 더 자유롭고 정확하게 시나리오를 작성할 수 있습니다. 예를 들어, "스시스왑에서 1 ETH 만큼의 BiFi 토큰을 스왑하고 얻어온 토큰의 1/3만큼만 BiFi-WETH Pool에 예금하기"처럼 단계 (스왑, 예금) 사이에 똑똑한 연산이 필요한 경우에도 ChainRunner Q에서는 자유롭게 할 수 있습니다. 이는 다른 서비스들이 흉내 내지 못하는 우리만의 장점입니다.',
        q3: 'Chainrunner Q를 사용하면 가스비를 절감할 수 있을까요?',
        a3: 'ChainRunner Q는 여러 서비스와 연결되어 이용자들에게 편의를 제공하고 있습니다. 분산되고 복잡한 여러 블록체인 서비스들과 한번에 연결해주는 기능을 하고 있기 때문에, 고유 네트워크 상에서 발생하는 수수료까지는 관여할 수 없습니다. 현재로서 가스비 절약을 위해선 이더리움 네트워크 대비 92%가량 저렴한 가스비를 제공하는 BSC 네트워크에서 서비스를 이용하시는것을 추천드립니다.',
        q4: 'Chainrunner Q에서는 어떤 디파이가 지원되고 있나요?',
        a4: '현재까지 Compound, AAVE, BiFi, BiFi X, Sushiswap 서비스를 활용하는 다양한 시나리오가 제공되고 있습니다. 최종적으로는 블록체인상의 모든 플랫폼을 다양한 방법으로 활용할 수 있도록, 더 많은 디파이 플랫폼과 다양한 시나리오를 점차적으로 추가해나갈 것입니다.',
        q5: '이용 수수료가 발생하나요?',
        a5: 'ChainRunner Q로 시작하는 서비스에는 현재 이용 수수료를 부과하고 있지 않습니다. 추후, 다양한 서비스와 시나리오가 확장됨에 따라 BFC 수수료 지불 정책을 적용하여 생태계를 확장해 나갈 예정입니다.',
        q6: '이용방법을 모르겠어요. 가이드가 있나요?',
        a6: '유튜브에 업로드되고 있는 <a target="_blank" href="https://www.youtube.com/watch?v=7Qe4wgfbNfA&list=PL2vuJd1qLwtAsbBnM4TE3Xgjh0KkG4FGE">가이드 영상을 참고하세요.</a>',
      },
    },
    about: {
      title: 'ChainRunner는 무엇인가요?',
      subtitle:
        'ChainRunner는 블록체인 생태계에서 서비스끼리 연결되어, 어려운 블록체인을 사용자가 쉽게 사용할 수 있게 하기 위해 개발되었습니다.',
      q1: 'The Problem',
      a1: '블록체인 서비스는 어렵습니다. 많은 이익을 가져다준다는 DeFi나 NFT는 사용하기 위해 생소한 crypto-wallet를 설치하고, 걸맞은 금융지식까지 갖추어야 합니다. 때때로 모든 기능을 사용하기 위해서는 smart contract를 직접 작성하도록 요구받기도 합니다. 또 서비스마다 사용하는 용어도 다르고 인터페이스도 달라서 같은 일을 하기 위해서도 매번 시간을 들여서 하나씩 새로 익혀야 하는 고충도 있습니다. 블록체인 서비스들을 제대로 사용하면 새로운 세상이 열린다지만, 블록체인을 이해하는 소수의 사람에게만 허락된 세상인 셈이죠. ',
      q2: '블록체인이 쉬워진다, ChainRunner',
      a2: 'ChainRunner는 이러한 문제들을 근본적으로 해결하기 위해서 그동안 여러 시도를 하였습니다. 사용자 입장에서는 수많은 블록체인 서비스들을 쉽게 이용할 수 있어야 하고, 반대로 플랫폼에서는 쉽고 빠르게 다양한 서비스를 제공하여 더 많은 사용자한테 도달할 수 있어야 하죠. 하지만 사용자나 서비스 중 어느 한쪽만 살짝 바꿔서는 상황이 쉽게 변하지 않았습니다. 결국 더 나은 블록체인 서비스 환경을 위해서는 사용자는 사용자대로, 서비스는 서비스대로 모든 일을 쉽게 하도록 업그레이드되어야 하는 것을 깨달았습니다.\n\n그래서 ChainRunner는 모든 곳에서 도움을 주는 엔진 형태로 발전하였습니다. ChainRunner 엔진은 사용자에게는 여러 서비스를 한번에 엮어 복잡한 기능을 대신 수행해주고, 서비스의 곁에서는 상황에 따라 다른 서비스들과 연계한 기능들을 바로 추가해주는, 서비스의 지능을 높이는 역할을 합니다.\n\nChainRunner는 앞으로 여러가지의 버전으로 발전합니다. 그중, "Q"는 ChainRunner가 당장 사용자 곁에서 모든 일을 쉽게 해주는 서비스입니다. 여러분의 컴퓨터 브라우저 위에서 각종 DeFi의 사용법을 요리책처럼 가지고, 블록체인의 복잡한 여러 서비스를 한번에 처리해 줍니다. 그리고 추후, 더 업그레이드된 ChainRunner Q에서는 여러분이 직접 불편하거나 입맛에 맞는 새로운 서비스를 원하는대로 추가하여, ChainRunner의 지능을 직접! 높일 수 있습니다!',
      q3: 'ChainRunner Q는 블록체인의 금융을 어떻게 바꿀 수 있을까요?',
      a3: {
        p1: '그동안 전통 금융 시장도 그 규모가 커지고 복잡해져가며, 이에 따른 불필요한 절차와 복잡함을 해결해주는 여러 가지 Killer App이 탄생했습니다. 지금은 금융이 더욱 캐주얼해지며, 문자 메시지 보내듯 편하게 송금할 수 있고, 직접 은행을 가지 않아도 나에게 가장 맞는 금융 상품을 쉽게 배우고 모바일로 지출 소비를 간편하게 관리할 수 있는 세상이 되었습니다.\n\n블록체인에서의 가상 자산 시장도 점차적으로 규모가 커지며 다양한 디파이 상품들이 탄생했습니다. 이제는 블록체인 금융 생태계도 발전하며 발생한 복잡함을 해결해주는 ChainRunner와 함께라면, 서비스들이 이렇게 발전됩니다:',
        li1: '내가 원하는 디파이 상품을 직접 추가해서, 다른 사람들도 같은 혜택을 누릴 수 있도록 공유할 수 있습니다.',
        li2: '여러 디파이를 일일이 배울 필요 없이 관리를 편하게 할 수 있습니다.',
        li3: '다른 디파이랑 손쉽게 비교해서 수익을 극대화 할 수 있습니다.',
        li4: '블록체인 위에 다양한 플랫폼을 손쉽고 올리고, 테스트 해볼 수 있습니다.',
        li5: '복잡하고 생소할 수 있는 NFT 구매부터 민팅을 포함한 다양한 작업도 쉽고 빠르게 처리할 수 있습니다.',
        p2: '바이프로스트 생태계는 ChainRunner가 탄생함으로서 다음과 같이 발전됩니다:',
        li6: '<a target="_blank" href="https://chrome.google.com/webstore/detail/biport-wallet/mapbhaebnddapnmifbbkgeedkeplgjmf">Biport</a> + ChainRunner: 여러분의 Biport 지갑에서 각종 DeFi, NFT 서비스의 기능을 바로 사용하실 수 있습니다.\nChainRunner가 할 수 있는 일은 지갑도 바로 할 수 있게 됩니다.\nBiport의 익숙한 인터페이스로 보유 자산을 특정 DeFi 서비스에 예치할 수 있게 되는 셈이죠.',
        li7: 'ChainRunner + <a target="_blank" href="https://bifi.finance/">BiFi</a> 또는 <a href="https://x.bifi.finance/">BiFi X</a>: 다른 서비스와의 경계가 허물어집니다.\nBiFi 나 BiFi-X 서비스를 사용하기 위해서 Uniswap이나 Sushiswap으로 토큰 스왑하고 준비하던 작업도\n이제는 ChainRunner를 통해서 쉽게 하실 수 있습니다.',
      },
      q4: 'Chainrunner의 시작, ChainRunner Q',
      a4: 'Q는 ChainRunner의 첫번째 주자입니다. Q는 여러분의 충실한 집사이자, 블록체인 서비스를 위한 만능 요리사입니다. 요리책과 같은 UX에서 여러분이 원하는 시나리오를 선택하면, 클릭 몇 번으로 손쉽게 원하는 모든 작업을 완료하실 수 있습니다.',
      q5: '왜 ChainRunner Q를 사용해야 할까요?',
      a5: 'Q는 아주 세밀한 자동화가 가능합니다. DeFi 서비스의 자동화를 표방하는 서비스들이 있지만, Q는 훨씬 더 자유롭고 정확하게 시나리오(요리법)를 작성할 수 있습니다. 예를 들어, "스시스왑에서 1 ETH 만큼의 BiFi 토큰을 스왑하고 얻어온 **토큰의 1/3만큼만** BiFi-WETH Pool에 예금하기"처럼 단계 (스왑, 예금) 사이에 똑똑한 연산이 필요한 경우에도 Q에서는 자유롭게 할 수 있습니다. 이는 다른 서비스들이 흉내 내지 못하는 Q만의 장점입니다.\n\nQ는 BIFROST City 생태계 안에서 커뮤니티와 함께 커갑니다. 사실 Q가 읽는 요리책은 코딩 지식을 가지고 있는 사용자들이 쉽게 작성할 수 있는 Markdown 형식으로 되어있습니다. 누구나 자신만의 전략이나 방법을 설명과 함께 ChainRunner 명령어를 추가하여 공유하고 발생한 수익을 나눌 수 있는 플랫폼으로 발전할 예정입니다.',
      learnMore: 'ChainRunner의 특징과 기술적 세부 정보가 궁금하다면 아래의 링크를 참조하세요.',
    },
    dex: {
      error: {
        '-1': 'Internal Error',
        10100: 'Bridging 하려는 값이 0보다 작거나 같음',
        10101: 'Bridge service에서 규정한 최소량보다 bridge 하려는 값이 작을 경우',
        10102: 'Bridge service에서 규정한 최대량보다 bridge 하려는 값이 클 경우',
        10103:
          '사용자가 소유한 bridging하려는 asset의 총량이 부족함 (bridging하려는 값(AmountIn)보다 적음)',
        10104:
          '사용자가 소유한 bridging하려는 asset의 총량이 부족함 (bridging하려는 값(AmountIn)보다 적음)',
        10200: 'Bridge 하려는 asset의 Symbol를 asset 목록에서 찾을 수 없음',
        10201: 'Bridge 하려는 asset을 현재 chain에서 이용할 수 없음',
        10202: 'Bridge 하려는 asset을 bridge 대상 network로 bridge할 수 없음',
        10203: 'Bridge 하려는 asset의 정보를 asset 목록에서 찾을 수 없음',
        10300: 'Bridge 대상 network의 chain ID를 network 목록에서 찾을 수 없음',
        10400: 'Bridge 하려는 asset의 pair를 지원해주는 DEX를 찾을 수 없음',
        20100: 'Swap하려는 값이 0보다 작거나 같음',
        20101:
          '사용자가 소유한 swap하려는 asset의 총량이 부족함 (swap하려는 값(AmountIn)보다 적음)',
        20102: '비정상적인 amount 값',
        20200: 'Swap 하려는 asset의 Symbol를 asset 목록에서 찾을 수 없음',
        20201: 'Swap 하려는 asset을 현재 chain에서 이용할 수 없음',
        20202: 'Swap 하려는 asset의 정보를 asset 목록에서 찾을 수 없음',
        20300: 'Swap 대상 asset의 Symbol를 asset 목록에서 찾을 수 없음',
        20301: 'Swap 대상 asset을 현재 chain에서 이용할 수 없음',
        20400: 'Swap하려는 asset의 pair를 swap해주는 DEX를 찾을 수 없음',
        30100: 'Swap 하려는 값이 0보다 작거나 같음',
        30101: 'Swap 하려는 asset의 양이 bridge service에서 규정한 최소량보다 적을 경우',
        30102: 'Swap 하려는 asset의 양이 bridge service에서 규정한 최소량보다 많을 경우',
        30103:
          '사용자가 소유한 swap하려는 asset의 총량이 부족함 (swap하려는 값(AmountIn)보다 적음)',
        30104: '비정상적인 amount 값',
        30200: 'Swap 하려는 asset의 ID을 asset 목록에서 찾을 수 없음',
        30201: 'Swap 하려는 asset을 현재 chain에서 이용할 수 없음',
        30202: 'Swap 하려는 asset을 swap의 대상 network으로 bridging할 수 없음',
        30203: 'Swap 하려는 asset의 정보를 asset 목록에서 찾을 수 없음',
        30204: 'Swap 하려는 asset의 bridge 대상 asset의 정보를 asset 목록에서 찾을 수 없음',
        30300: 'Swap의 대상 network의 chain ID를 network 목록에서 찾을 수 없음',
        30301: 'Swap의 대상 asset을 swap의 대상 network에서 이용할 수 없음',
        30302: 'Swap의 대상 asset의 정보를 asset 목록에서 찾을 수 없음',
        30400: 'Swap의 대상 network의 chain ID를 network 목록에서 찾을 수 없음',
        30500:
          'Swap 하려는 asset의 pair에 대해 swap해주는 DEX를 swap의 대상 network에서 찾을 수 없음',
      },
    },
  },
};

export default resource;
