import styled from 'styled-components';
import Color from 'styles/colors';

const CodeBox = styled.div`
  padding: 10px 20px;
  border-radius: 3px;
  border: solid 1px ${Color.Border};
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export default CodeBox;
