import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';

import useIsMobile from 'hooks/useIsMobile';
import {
  selectedCategoryAtom,
  level1Atom,
  level2Atom,
  level3Atom,
  selectedScenarioAtom,
  showTabAtom,
} from 'states/mainStore';
import ScenarioBackground from 'assets/select-scenario-background.svg';
import invokeMethodAsync from 'lib/invokeMethodAsync';
import Scenario from 'types/scenario';
import DisableButtonStyle from 'styles/disableButtonStyle';
import Color from 'styles/colors';
import BASE_URL from 'lib/baseUrl';
import Footer from './footer';
import DropdownScenario from './dropdownScenario';

const Container = styled.div<{ isMobile: boolean }>`
  top: 52px;
  z-index: 2;
  position: ${({ isMobile }) => (isMobile ? 'absolute' : 'inherit')};
  width: 65vw;
  min-width: 1092px;
  height: 100vh;
  background-color: #121b1f;
  border: solid 1px ${Color.Border};
  border-left: 0;
  transform: translateZ(0);
`;

const MenuContainer = styled.div<{ isMobile: boolean }>`
  position: absolute;
  width: ${({ isMobile }) => (isMobile ? '100vw' : '20vw')};
  min-width: 336px;
  height: 100vh;
  background: #283c46;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
`;

const Title = styled.div`
  width: 274px;
  font-size: 24px;
  margin-top: 50px;
  margin-bottom: 40px;
`;

const DropDownContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 24px;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`;

const Go = styled.button`
  background-color: #238fc8;
  color: white;
  font-weight: bold;
  width: 186px;
  height: 40px;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 80px;
  z-index: 2;
`;

const SelectScenarioDetail: React.FC = () => {
  const [, setShowTab] = useAtom(showTabAtom);
  const [root] = useAtom(selectedCategoryAtom);
  const [level1, setLevel1] = useAtom(level1Atom);
  const [level2, setLevel2] = useAtom(level2Atom);
  const [level3, setLevel3] = useAtom(level3Atom);
  const [selectedScenario, setSelectedScenario] = useAtom(selectedScenarioAtom);
  const navigate = useNavigate();

  const isMobile = useIsMobile();

  const depth = useMemo(() => {
    if (root === undefined) return 0;

    return root.Depth;
  }, [root]);

  const lastNode = useMemo(() => {
    return [level1, level2, level3][depth - 1];
  }, [depth, level1, level2, level3]);

  useEffect(() => {
    if (root !== undefined) {
      if (root.SubList.length > 0) {
        setLevel1(root.SubList[0]);
      }
      setLevel2(undefined);
      setLevel3(undefined);
    }
  }, [root]);

  useEffect(() => {
    setLevel2(undefined);
    setLevel3(undefined);
  }, [level1]);

  useEffect(() => {
    setLevel3(undefined);
  }, [level2]);

  const goClick = async () => {
    const file = lastNode?.File;

    if (lastNode === undefined || file === undefined) return;

    const url = file.startsWith('http') ? file : `${BASE_URL}${lastNode.File}`;
    const content = await (await fetch(url)).text();
    const jsonRes = await invokeMethodAsync<{ Service: Scenario }>(
      'ToGoEvaluator',
      'parser.parseService',
      content,
    );

    if (!jsonRes.Ok) {
      throw new Error(jsonRes.Error);
    }

    const service = jsonRes.Service;

    if (service) {
      service.Class = lastNode.Class;
      if (selectedScenario?.Title !== service?.Title) {
        setSelectedScenario(service);

        if (isMobile) setShowTab(false);

        navigate(`/${lastNode.File?.split('/')?.[2]}`);
      }
    }
  };

  return (
    <Container isMobile={isMobile}>
      {!isMobile && (
        <img
          src={ScenarioBackground}
          alt=""
          style={{
            width: '65vw',
            minWidth: '1092px',
            height: '100vh',
            maxHeight: '100vh',
            position: 'absolute',
            top: '0px',
          }}
        />
      )}
      <MenuContainer isMobile={isMobile}>
        <Title>{root?.Class}</Title>
        <DropDownContainer>
          {depth >= 1 && (
            <DropdownScenario
              target={level1}
              setTarget={setLevel1}
              subList={root?.SubList ?? []}
              placeholderItem={root?.SubList[0]}
            />
          )}
          {depth >= 2 && (
            <DropdownScenario
              target={level2}
              setTarget={setLevel2}
              subList={level1?.SubList ?? []}
              placeholderItem={root?.SubList[0]?.SubList?.[0]}
            />
          )}
          {depth >= 3 && (
            <DropdownScenario
              target={level3}
              setTarget={setLevel3}
              subList={level2?.SubList ?? []}
              placeholderItem={root?.SubList[0]?.SubList?.[0].SubList?.[0]}
            />
          )}
        </DropDownContainer>
        <Go
          type="button"
          onClick={goClick}
          style={DisableButtonStyle(() => lastNode?.File !== undefined)}>
          GO
        </Go>
      </MenuContainer>
      <div style={{ width: '65vw', padding: '0 52px', position: 'absolute', bottom: '0' }}>
        <Footer />
      </div>
    </Container>
  );
};

export default SelectScenarioDetail;
