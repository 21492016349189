import React, { useMemo, useRef, useEffect } from 'react';
import styled from 'styled-components';
// import scenes from 'lib/scenes';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import useIsMobile from 'hooks/useIsMobile';
import useMenus from 'hooks/useMenus';
import {
  languageAtom,
  networkAtom,
  categoriesAtom,
  selectedCategoryAtom,
  selectedScenarioAtom,
  showTabAtom,
} from 'states/mainStore';
import Category from 'types/menu';
import GA from 'lib/gaService';
import useChainrunnerPopup from 'hooks/useChainrunnerPopup';
import Color from 'styles/colors';
import { ReactComponent as SymbolTextLogo } from 'assets/symbol-text-logo.svg';

const Container = styled.div<{ isMobile: boolean }>`
  width: 100%;
  margin-top: ${({ isMobile }) => (isMobile ? '0px' : 'inherit')};
  height: ${({ isMobile }) => (isMobile ? 'calc(100vh - 100px)' : 'inherit')};
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  /* padding-bottom: 50vh; */
  background-color: #131d26;
  transform: translateX(0px);
`;

const Tab = styled.div<{ enabled: boolean; isMobile: boolean }>`
  display: flex;
  align-items: center;
  min-width: calc(15vw - 24px);
  margin: 10px;
  height: calc(8vh - 20px);
  text-align: start;
  padding: 0 40px;
  cursor: pointer;
  transition: color 0.15s ease-in;
  z-index: 1000;
  font-weight: bold;
  color: white;
  /* pointer-events: ${(props) => (props.enabled ? 'inherit' : 'none')}; */
  opacity: ${(props) => (props.enabled ? '1' : '0.5')};
  border-bottom: ${({ isMobile }) => (isMobile ? `1px solid ${Color.Border}` : '0')};
  border-radius: ${({ isMobile }) => (isMobile ? '0px' : '12px')};

  &:hover {
    background-color: rgba(216, 216, 216, 0.125);
  }
`;

const Glider = styled.span<{ currentIndex: number; isMobile: boolean }>`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  height: calc(8vh - 20px);
  margin: 10px 10px 14px 10px;
  width: ${({ isMobile }) => (isMobile ? '100vw' : 'calc(15vw - 24px)')};
  min-width: 252px;
  max-width: 15vw;
  background-color: rgba(216, 216, 216, 0.25);
  transition: 0.15s ease;
  transform: translate3D(0, ${({ currentIndex }) => `calc((8vh - 10px) * ${currentIndex})`}, 0);
  border-radius: 12px;
`;

const Tabs: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [network] = useAtom(networkAtom);
  const menus = useMenus();
  const [categories, setCategories] = useAtom(categoriesAtom);
  const [selectedClass, setSelectedClass] = useAtom(selectedCategoryAtom);
  const [, setSelectedScenario] = useAtom(selectedScenarioAtom);
  const [, setShowTab] = useAtom(showTabAtom);
  const gliderEl = useRef<HTMLDivElement>(null);
  const [showPopup] = useChainrunnerPopup('popup.noConnectedWallet.message');
  const location = useLocation();

  const isMobile = useIsMobile();

  useEffect(() => {
    setCategories(menus as Category[]);
  }, [menus]);

  const classIndex = useMemo(() => {
    if (selectedClass !== undefined) {
      // [about, dex, bridge, swap].length = 4
      return categories.findIndex((x) => x.Class === selectedClass.Class) + 1;
    }

    switch (location.pathname) {
      case '/dex':
        return 1;
      case '/bridge':
        return 2;
      case '/swap':
        return 3;
      default:
        return -100;
    }
  }, [selectedClass, categories, location]);

  return (
    <Container isMobile={isMobile}>
      {isMobile && (
        <Tab
          enabled
          isMobile
          onClick={() => {
            setSelectedClass(undefined);
            setSelectedScenario(undefined);
            navigate('/home');
            setShowTab(false);
          }}>
          <SymbolTextLogo />
        </Tab>
      )}
      <Tab
        enabled
        isMobile={isMobile}
        onClick={() => {
          setSelectedClass(undefined);
          setSelectedScenario(undefined);
          navigate('/about');
        }}>
        {t('tab.about')}
      </Tab>
      {/* <Tab
        enabled
        isMobile={isMobile}
        onClick={() => {
          setSelectedClass(undefined);
          setSelectedScenario(undefined);
          navigate('/dex');
        }}>
        {t('Cross Chain Swap')}
      </Tab> */}
      {categories.map((menu) => (
        <Tab
          key={menu.Class}
          enabled={menu.SubList.length > 0 && network !== undefined}
          isMobile={isMobile}
          onClick={() => {
            GA.trackTabClickEvent({ label: menu.Class });

            if (menu.SubList.length === 0) return;

            if (network === undefined) {
              showPopup();
              return;
            }

            if (isMobile) setShowTab(false);

            setSelectedClass(menu);
            setSelectedScenario(undefined);

            navigate('/home');
          }}>
          {menu.Class}
        </Tab>
      ))}
      <Glider
        ref={gliderEl}
        currentIndex={isMobile ? classIndex + 1 : classIndex}
        isMobile={isMobile}
      />
    </Container>
  );
};

export default Tabs;
