import React, { useEffect, useMemo } from 'react';
import { useAtom } from 'jotai';
import { useParams } from 'react-router-dom';

import useIsMobile from 'hooks/useIsMobile';
import { languageAtom, selectedScenarioAtom } from 'states/mainStore';
import BASE_URL from 'lib/baseUrl';
import invokeMethodAsync from 'lib/invokeMethodAsync';
import Scenario from 'types/scenario';
import MdContainer from 'components/common/mdContainer';
import ContentContainer from 'components/common/contentContainer';
import ScenarioHeader from './scenarioHeader';
import ScenarioContainer from './scenarioContainer';
import Step from './step';
import Footer from './footer';

const ScenarioPage = () => {
  const [language] = useAtom(languageAtom);
  const [selectedScenario, setSelectedScenario] = useAtom(selectedScenarioAtom);
  const { fileName } = useParams();

  const isMobile = useIsMobile();

  useEffect(() => {
    (async () => {
      if (selectedScenario === undefined) {
        if (fileName) {
          const url = `${BASE_URL}/${language.toUpperCase()}/${fileName}`;
          const content = await (await fetch(url)).text();
          const jsonRes = await invokeMethodAsync<{ Service: Scenario }>(
            'ToGoEvaluator',
            'parser.parseService',
            content,
          );

          if (!jsonRes.Ok) {
            throw new Error(jsonRes.Error);
          }

          setSelectedScenario(jsonRes.Service);
        }
      }
    })();
  }, []);

  const headerTitle = useMemo(() => {
    if (selectedScenario?.Class) {
      return `${selectedScenario.Class} / ${selectedScenario.Title}`;
    }

    return selectedScenario?.Title ?? '';
  }, [selectedScenario]);

  if (selectedScenario === undefined) return <></>;

  return (
    <ContentContainer>
      <ScenarioHeader title={headerTitle} />
      <ScenarioContainer isMobile={isMobile}>
        <MdContainer markdown={selectedScenario.Markdown} />
        {selectedScenario.Steps.map((step, i) => (
          <Step key={JSON.stringify(step)} index={i} step={step} />
        ))}
        <div style={{ height: '48px', width: '100%' }} />
        <Footer />
      </ScenarioContainer>
    </ContentContainer>
  );
};

export default ScenarioPage;
