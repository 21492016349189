import React, { useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { useAtom } from 'jotai';
import {
  languageAtom,
  showTabAtom,
  selectedScenarioAtom,
  selectedCategoryAtom,
  categoriesAtom,
  networkMetasAtom,
  networkAtom,
} from 'states/mainStore';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useIsMobile from 'hooks/useIsMobile';
import HasChildMenu from 'types/hasChildMenu';
import BG from 'assets/background-particle.png';
import { findCoinbase, findMetamask } from 'lib/findProvider';
import { ReactComponent as Expand } from 'assets/expand.svg';
import BASE_URL from 'lib/baseUrl';
import invokeMethodAsync from 'lib/invokeMethodAsync';
import Scenario from 'types/scenario';
import StrokeColors from 'lib/strokeColors';
import { getNetworkInfoByChainId } from 'lib/getNetworkInfo';
import Tree from './tree.js';

const Container = styled.div`
  height: 100vh;
  background-image: url(${BG});
`;

const Header = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  height: 100px;
  width: 100vw;
  line-height: 100px;
  padding: 0 60px;
`;

const HeaderTitle = styled.div`
  flex-grow: 1;
  text-align: center;
  margin-right: 32px;
  font-size: 24px;
`;

const Content = styled.div`
  height: 100vh;
  width: ${Math.max(document.documentElement.clientWidth, 1200)}px;
  overflow: scroll;
  overflow-x: scroll;
  padding: 20px 0;
  padding-top: 100px;
`;

type Node = {
  Class: string;
  SubList?: HasChildMenu[];
  File?: string;
  Title?: string;
  Stroke: string;
};

const getStroke: any = (node: any, retryCount = 1) => {
  if (retryCount > 10) return undefined;

  const stroke = node?.source?.parent?.data?.Stroke || node?.parent?.data?.Stroke;
  if (stroke === undefined) return getStroke(node?.source?.parent, retryCount + 1);

  return stroke;
};

const SiteMap = () => {
  const [, setShowTab] = useAtom(showTabAtom);
  const [language] = useAtom(languageAtom);
  const [selectedScenario, setSelectedScenario] = useAtom(selectedScenarioAtom);
  const [, setSelectedClass] = useAtom(selectedCategoryAtom);
  const [categories] = useAtom(categoriesAtom);
  const [network, setNetwork] = useAtom(networkAtom);
  const [networkMetas] = useAtom(networkMetasAtom);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const svg = useRef<HTMLDivElement>(null);

  const onNodeClick = async () => {
    if (isMobile) return;

    const file = localStorage.getItem('file') ?? '';
    const category = localStorage.getItem('category') ?? '';

    localStorage.removeItem('file');
    localStorage.removeItem('category');

    if (!file) return;

    const url = file.startsWith('http') ? file : `${BASE_URL}${file}`;
    const content = await (await fetch(url)).text();
    const jsonRes = await invokeMethodAsync<{ Service: Scenario }>(
      'ToGoEvaluator',
      'parser.parseService',
      content,
    );

    if (!jsonRes.Ok) {
      throw new Error(jsonRes.Error);
    }

    const service = jsonRes.Service;

    if (service) {
      service.Class = category;
      if (selectedScenario?.Title !== service.Title) {
        setSelectedClass(categories.find((c) => c.Class === category));
        setSelectedScenario(service);

        if (isMobile) setShowTab(false);

        navigate(`/${file?.split('/')?.[2]}`, { replace: true, state: { service, url } });
      }
    }
  };

  const attachTree = useCallback(() => {
    (async () => {
      const networkMenusJson = await Promise.all(
        networkMetas.map(async (networkJson) => {
          let networkMenuJsonUrl = `${BASE_URL}/`;

          switch (language.toUpperCase()) {
            case 'EN':
              networkMenuJsonUrl += networkJson['Scenarios-EN'];
              break;
            case 'KR':
              networkMenuJsonUrl += networkJson['Scenarios-KR'];
              break;
            default:
              break;
          }

          const networkMenuJson = await (await fetch(networkMenuJsonUrl)).json();

          return networkMenuJson;
        }),
      );

      const chart = Tree(
        {
          Class: 'Chainrunner Q',
          SubList: networkMenusJson.map<Node>((networkMenuJson, i) => {
            return {
              Class: networkMetas[i].Title,
              SubList: networkMenuJson,
              Stroke: StrokeColors[i],
            };
          }),
          Stroke: '#fff',
        },
        {
          path: null,
          children: (d: Node) =>
            d.SubList?.filter((data) => data.Class !== 'More scenarios to come'),
          sort: undefined,
          height: undefined,
          fillOpacity: 1,
          strokeLinejoin: undefined,
          strokeLinecap: undefined,
          label: (d: Node) => (d.File ? d.Title : d.Class),
          title: (d: Node) => (d.File ? d.Title : d.Class), // hover text, File이 있으면 Title 사용, 없으면 Class 사용
          width: Math.max(document.documentElement.clientWidth, 1200),
          onNodeClick,
          isMobile,
        },
      );

      const c = svg.current;
      if (c && chart !== null) {
        if (c.children.length > 0) {
          c.removeChild(c.children[0]);
        }
        c.appendChild(chart);
      }
    })();
  }, [networkMetas]);

  const chainChanged = useCallback(
    async (...[chainId]: unknown[]) => {
      const res = await invokeMethodAsync<{ Network: number }>(
        'ToGoEvaluator',
        'evaluator.updateChain',
      );
      if (!res.Ok) {
        setNetwork(undefined);
      }

      const newNetwork = getNetworkInfoByChainId(res.Network);
      setNetwork(newNetwork);

      onNodeClick();
    },
    [network],
  );

  useEffect(() => {
    attachTree();

    const { biport } = window;

    const coinbase = findCoinbase();
    const metamask = findMetamask();

    coinbase?.on('chainChanged', chainChanged);
    biport?.on('chainChanged', chainChanged);
    metamask?.on('chainChanged', chainChanged);

    if (window.location.pathname === '/') {
      navigate('/home');
    }

    return () => {
      coinbase?.removeListener('chainChanged', chainChanged);
      biport?.removeListener('chainChanged', chainChanged);
      metamask?.removeListener('chainChanged', chainChanged);
    };
  }, []);

  useEffect(() => {
    attachTree();
  }, [networkMetas]);

  return (
    <Container>
      <Header>
        <Expand
          style={{ width: '32px', height: '32px', transform: 'rotate(270deg)', cursor: 'pointer' }}
          onClick={() => navigate('/')}
        />
        <HeaderTitle>{t('sitemap.showAllMenus')}</HeaderTitle>
      </Header>
      <Content ref={svg} />
    </Container>
  );
};

export default SiteMap;
