import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';

import selectedWalletIndexAtom from 'states/connectWalletStore';
import useIsMobile from 'hooks/useIsMobile';
import { loadingWalletAtom, biportExistAtom } from 'states/walletStore';
import useConnectWallet from 'hooks/useConnectWallet';
import ConnectWalletProps from 'types/connectWalletProps';
import useWalletTitle from 'hooks/useWalletTitle';
import useInstallWalletPopup from 'hooks/useInstallWalletPopup';
import LogoBiport from 'assets/logo-biport.png';
import AddressInnerContainer from './address/addressInnterContainer';
import WalletTitle from './address/walletTitle';
import ConnectContainer from './connect/connectContainer';
import InstallWallet from './connect/installWallet';
import QRCode from './connect/qrcode';

const ConnectBiportButton: React.FC<ConnectWalletProps> = ({ hidePopup }) => {
  const isMobile = useIsMobile();
  const [biportExist] = useAtom(biportExistAtom);
  const [loadingWallet] = useAtom(loadingWalletAtom);
  const [selectedWalletIndex] = useAtom(selectedWalletIndexAtom);
  const biportTitle = useWalletTitle('Biport');
  const [showInstallWalletPopup] = useInstallWalletPopup(
    'https://s3.ap-northeast-2.amazonaws.com/bifi.finance/qr/biport_app_bifi.png',
    'https://chrome.google.com/webstore/detail/biport-wallet/mapbhaebnddapnmifbbkgeedkeplgjmf',
  );

  const connectWallet = useConnectWallet('evaluator.connectBiport', 'biport');

  const connectBiportClick: React.MouseEventHandler<HTMLElement> = async () => {
    if (biportExist) {
      connectWallet();
      hidePopup();
    } else {
      showInstallWalletPopup();
    }
  };

  if (loadingWallet) return <></>;

  return (
    <>
      <ConnectContainer isMobile={isMobile} onClick={connectBiportClick}>
        <AddressInnerContainer>
          <img src={LogoBiport} alt="" />
          <WalletTitle>{biportTitle}</WalletTitle>
        </AddressInnerContainer>
      </ConnectContainer>
    </>
  );
};

export default ConnectBiportButton;
