import Asset from 'types/asset';
import invokeMethodAsync from './invokeMethodAsync';

const loadAssets = async (currencies: string[]) => {
  const res = await invokeMethodAsync<{ Balances: Asset[] }>(
    'ToGoEvaluator',
    'evaluator.getBalances',
    currencies,
  );
  return res.Balances;
};

export default loadAssets;
