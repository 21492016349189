class NativeCurrency {
  public name: string;

  public symbol: string;

  public decimals: number;

  constructor(name: string, symbol: string, decimals = 18) {
    this.name = name;
    this.symbol = symbol;
    this.decimals = decimals;
  }
}

export default NativeCurrency;
