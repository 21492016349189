import styled from 'styled-components';

const ConnectContainer = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? 'initial' : '100%')};
  height: ${({ isMobile }) => (isMobile ? '52px' : 'initial')};
  display: flex;
  border-radius: 3px;
  background-color: #238fc8;
  padding: 3px 12px;
  justify-content: space-between;
  line-height: ${({ isMobile }) => (isMobile ? '52px' : '36px')};
  margin-top: 12px;
  cursor: pointer;
  align-items: center;
`;

export default ConnectContainer;
