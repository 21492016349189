import React from 'react';
import { usePopup } from 'react-hook-popup';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from 'assets/close.svg';
import DimmedBackground from 'components/common/dimmedBackground';
import PopupBackground from 'components/common/popupBackground';
import Confirm from 'components/common/confirm';

const useChainrunnerPopup = (i18nKey: string) => {
  const { t } = useTranslation();

  const [showPopup, hidePopup] = usePopup(i18nKey, () => (
    <DimmedBackground>
      <PopupBackground>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <CloseIcon
            onClick={() => hidePopup()}
            style={{ width: '24px', height: '24px', cursor: 'pointer' }}
          />
        </div>
        <div style={{ margin: '0 40px', textAlign: 'center' }}>{t(i18nKey)}</div>
        <Confirm onClick={() => hidePopup()}>{t('popup.confirm')}</Confirm>
      </PopupBackground>
    </DimmedBackground>
  ));

  return [showPopup, hidePopup];
};

export default useChainrunnerPopup;
