import en from './en';
import kr from './kr';
import zh from './zh';

const resources = {
  en,
  kr,
  zh,
};

export default resources;
