class Network {
  public chainHexId: string;

  public chainId: number;

  public allowed: boolean;

  public color: string;

  public rpcUrl: string;

  public blockExplorerUrl: string;

  public symbol: string;

  public name: string;

  public shortName: string;

  constructor(
    chainHexId: string,
    allowed: boolean,
    color: string,
    name: string,
    rpcUrl: string,
    blockExplorerUrl = '',
    symbol = 'ETH',
    shortName = '',
  ) {
    this.chainHexId = chainHexId;
    this.chainId = parseInt(this.chainHexId, 16);
    this.allowed = allowed;
    this.color = color;
    this.name = name;
    this.rpcUrl = rpcUrl;
    this.blockExplorerUrl = blockExplorerUrl;
    this.symbol = symbol;
    this.shortName = shortName || this.name;
  }
}

export default Network;
