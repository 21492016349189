import React from 'react';
import styled from 'styled-components';
import { usePopup } from 'react-hook-popup';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from 'assets/close.svg';
import DimmedBackground from 'components/common/dimmedBackground';
import PopupBackground from 'components/common/popupBackground';
import ConnectMetamaskButton from 'components/main/connectMetamaskButton';
import ConnectBiportButton from 'components/main/connectBiportButton';
import ConnectCoinbaseButton from 'components/main/connectCoinbaseButton';

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin: 12px 0;
  white-space: pre-wrap;
`;

const useConnectWalletPopup = () => {
  const { t } = useTranslation();

  const [showPopup, hidePopup] = usePopup('useConnectWalletPopup', () => (
    <DimmedBackground>
      <PopupBackground style={{ height: 'initial' }}>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <CloseIcon
            onClick={() => hidePopup()}
            style={{ width: '24px', height: '24px', cursor: 'pointer' }}
          />
        </div>
        <Title>{t('popup.connectWallet.title')}</Title>
        <ConnectCoinbaseButton hidePopup={hidePopup} />
        <ConnectBiportButton hidePopup={hidePopup} />
        <ConnectMetamaskButton hidePopup={hidePopup} />
      </PopupBackground>
    </DimmedBackground>
  ));

  return [showPopup, hidePopup];
};

export default useConnectWalletPopup;
