import React, { useEffect } from 'react';
import styled from 'styled-components';
import { CodeBlock, dracula } from 'react-code-blocks';
import { useAtom } from 'jotai';

import StepStore from 'states/stepStore';
import CodeBoxHeader from './codeBoxHeader';

const Container = styled.div`
  /* width: 100%;
  margin-top: 16px;
  font-family: 'Anonymous Pro';
  font-weight: normal;
  font-style: normal;
  white-space: pre-wrap; */

  font-family: 'Anonymous Pro' !important;
  font-weight: normal !important;
  font-style: normal !important;

  & > span {
    padding: 10px 20px;
    background-color: rgba(255, 255, 255, 0.1) !important;
  }

  & > div > span {
    background-color: transparent !important;
  }

  & > span > code {
    /* display: flex;
    flex-wrap: wrap; */
    padding: 0px !important;
  }

  & button {
    display: none;
    border: 0;
  }
`;

type CodeProps = {
  stepStore: StepStore;
};

const Code: React.FC<CodeProps> = ({ stepStore }: CodeProps) => {
  const [code] = useAtom(stepStore.codeAtom);

  const theme = {
    ...dracula,
    commentColor: '#F9B10B',
  };

  return (
    <>
      <CodeBoxHeader>Code</CodeBoxHeader>

      <Container>
        <CodeBlock
          text={code}
          language="js"
          showLineNumbers={false}
          startingLineNumber={1}
          theme={theme}
          wrapLines
        />
      </Container>
    </>
  );
};

export default Code;
