import styled from 'styled-components';

const PopupBackground = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 460px;
  height: 216px;
  padding: 20px;
  border: solid 1px #25313c;
  background-color: #131d26;

  @media screen and (max-width: 767px) {
    width: 100vw;
  }
`;

export default PopupBackground;
