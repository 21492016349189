import i18n from 'i18next';
import defaultLanguage from 'lib/defaultLanguage';
import { initReactI18next } from 'react-i18next';

import resources from './resources';

const lang = defaultLanguage();

i18n.use(initReactI18next).init({
  resources,
  lng: lang,
  fallbackLng: lang,
  interpolation: {
    escapeValue: false,
  },
});
