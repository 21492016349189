import React, { useEffect } from 'react';
import { useAtom } from 'jotai';
import styled from 'styled-components';

import StepStore from 'states/stepStore';
import StepState from 'types/stepState';
import CodeBox from './codeBox';
import CodeBoxHeader from './codeBoxHeader';

const Container = styled(CodeBox)<{ stepState: StepState }>`
  width: 100%;
  min-height: 120px;
  margin-top: 16px;
  font-family: 'Anonymous Pro';
  font-weight: normal;
  font-style: normal;
  white-space: pre-wrap;
  color: ${(props) => (props.stepState === 'error' ? 'red' : 'inherit')};
  border: ${(props) => (props.stepState === 'error' ? '2px solid red' : '0')};
  overflow-x: auto;
`;

type OutputProps = {
  stepStore: StepStore;
};

const Output: React.FC<OutputProps> = ({ stepStore }: OutputProps) => {
  const [stepState] = useAtom(stepStore.stepStateAtom);
  const [codeOutput] = useAtom(stepStore.codeOutputAtom);
  const [codeError] = useAtom(stepStore.codeErrorAtom);

  return (
    <>
      <CodeBoxHeader>Output</CodeBoxHeader>
      <Container stepState={stepState}>{codeOutput || codeError}</Container>
    </>
  );
};

export default Output;
