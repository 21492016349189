import React from 'react';
import styled from 'styled-components';
import { useAtom } from 'jotai';
import { Menu as MenuIcon } from '@mui/icons-material';

import useIsMobile from 'hooks/useIsMobile';
import { selectedCategoryAtom, showTabAtom } from 'states/mainStore';

const Hambuger = () => {
  const [showTab, setShowTab] = useAtom(showTabAtom);
  const [, setSelectedClass] = useAtom(selectedCategoryAtom);

  const isMobile = useIsMobile();

  if (!isMobile) return <></>;

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{ width: 'fit-content' }}
        aria-hidden="true"
        onKeyPress={() => undefined}
        onClick={() => {
          setSelectedClass(undefined);
          setShowTab(!showTab);
        }}>
        <MenuIcon style={{ marginRight: '16px', cursor: 'pointer' }} />
      </div>
    </div>
  );
};

export default Hambuger;
