import { useMemo } from 'react';
import { useAtom } from 'jotai';

import { walletAddressAtom } from 'states/mainStore';

function useConnected() {
  const [walletAddress] = useAtom(walletAddressAtom);

  const connected = useMemo(() => {
    return walletAddress?.length > 0;
  }, [walletAddress]);

  return connected;
}

export default useConnected;
