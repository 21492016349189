import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import ExpandIcon from 'assets/expand.svg';

const DropDownContainer = styled.div`
  position: relative;
  font-size: 14px;
  width: 274px;
  height: 52px;
`;

const DropDownLabelContainer = styled.div<{ expand: boolean; enable: boolean }>`
  display: block;
  border: ${({ enable }) => (enable ? '2px solid #379ef3' : '')};
  border-radius: ${({ expand }) => (expand ? '12px 12px 0 0' : '12px')};
  background-color: #ffffff33;
  opacity: ${({ enable }) => (enable ? '1' : '0.5')};
  pointer-events: ${({ enable }) => (enable ? 'inherit' : 'none')};
  cursor: pointer;
  height: 52px;
  transition: 0.15s all ease-out;

  & > .expand {
    position: absolute;
    right: 12px;
    top: calc(50% - 12px);
    transition: 0.15s all ease-out;
  }
`;

interface DropDownLabelProps extends HTMLAttributes<HTMLDivElement> {
  expand: boolean;
  enable?: boolean;
}

const DropDownLabel = React.forwardRef<HTMLDivElement, DropDownLabelProps>(
  ({ expand, children, enable = true, onClick }: DropDownLabelProps, ref) => {
    return (
      <DropDownLabelContainer ref={ref} expand={expand} onClick={onClick} enable={enable}>
        <img
          src={ExpandIcon}
          alt=""
          className="expand"
          style={expand ? {} : { transform: 'rotate(180deg)' }}
        />
        {children}
      </DropDownLabelContainer>
    );
  },
);

DropDownLabel.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

DropDownLabel.displayName = 'DropDownLabel';

const DropDownLabelPlaceHolder = styled.div`
  padding: 20px;
  height: 80px;
  line-height: 40px;
  font-size: 16px;
`;

const DropDownList = styled.div<{ expand: boolean }>`
  background-color: rgba(255, 255, 255, 0.1);
  position: relative;
  top: 0px;
  border-radius: ${({ expand }) => (expand ? '0 0 12px 12px' : '12px')};
  transform-origin: 50% 0;
  transform: scale(1, 0);
  transition: transform 0.15s ease-in-out 0.15s;
  max-height: 66vh;
  overflow-y: auto;
  z-index: 1;

  ${({ expand }) =>
    expand &&
    css`
      transform: scale(1, 1);
      transition-delay: 0s;
    `}

  &>div:hover {
    transition: background-color 0.15s ease;
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const DropDownOption = styled.label<{ expand: boolean }>`
  cursor: pointer;
  display: block;
  opacity: 0;
  transition: opacity 0.15s ease-in-out;

  &:hover {
    background-color: #4f6069;
  }

  ${({ expand }) =>
    expand &&
    css`
      opacity: 1;
      transition-delay: 0.2s;
    `}
`;

export { DropDownContainer, DropDownLabel, DropDownLabelPlaceHolder, DropDownList, DropDownOption };
