import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as TreeIcon } from 'assets/tree.svg';

const Container = styled.div`
  height: 48px;
  cursor: pointer;
  background: #238fc8;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ShowAllMenus = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/sitemap');
  };

  return (
    <Container onClick={handleClick}>
      <TreeIcon style={{ marginRight: '8px' }} />
      <div>{t('sitemap.showAllMenus')}</div>
    </Container>
  );
};

export default ShowAllMenus;
