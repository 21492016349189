import React from 'react';
import styled from 'styled-components';

const Image = styled.img`
  width: 200px;
  height: 200px;
  cursor: pointer;
  margin: 8px auto;
`;

type QRCodeProps = {
  src: string;
};

const QRCode: React.FC<QRCodeProps> = ({ src }: QRCodeProps) => {
  return <Image src={src} alt="" />;
};

export default QRCode;
