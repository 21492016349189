import * as d3 from 'd3';
import Wallet from 'lib/wallet';
import { getNetworkInfoByName } from 'lib/getNetworkInfo';

const wallet = new Wallet();

const getStroke = (node, retryCount = 1) => {
  if (retryCount > 10) return undefined;

  const stroke =
    node?.target?.parent?.data?.Stroke ||
    node?.source?.parent?.data?.Stroke ||
    node?.parent?.data?.Stroke;
  if (stroke === undefined) return getStroke(node?.source?.parent || node?.parent, retryCount + 1);

  return stroke;
};

const findClass = (node, retryCount = 1) => {
  if (retryCount > 10) return undefined;

  if (node === null || node === undefined) {
    console.log('node is empty');
    return undefined;
  }

  const { parent } = node;

  const title = parent?.data?.Class;

  if (
    title === 'Ethereum mainnet' ||
    title === 'BSC mainnet' ||
    title === 'Klaytn mainnet (Cypress)'
  ) {
    return node.data.Class;
  }

  return findClass(parent, retryCount + 1);
};

const findNetwork = (node, retryCount = 1) => {
  if (retryCount > 10) return undefined;

  if (node === null || node === undefined) {
    console.log('node is empty');
    return undefined;
  }

  const { parent } = node;

  if (node.depth === 1) {
    return getNetworkInfoByName(node.data.Class);
  }

  return findNetwork(parent, retryCount + 1);
};

// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/tree
function Tree(
  data,
  {
    // data is either tabular (array of objects) or hierarchy (nested objects)
    path, // as an alternative to id and parentId, returns an array identifier, imputing internal nodes
    id = Array.isArray(data) ? (d) => d.id : null, // if tabular data, given a d in data, returns a unique identifier (string)
    parentId = Array.isArray(data) ? (d) => d.parentId : null, // if tabular data, given a node d, returns its parent’s identifier
    children, // if hierarchical data, given a d in data, returns its children
    tree = d3.tree, // layout algorithm (typically d3.tree or d3.cluster)
    sort, // how to sort nodes prior to layout (e.g., (a, b) => d3.descending(a.height, b.height))
    label, // given a node d, returns the display name
    title, // given a node d, returns its hover text
    width = 640, // outer width, in pixels
    height, // outer height, in pixels
    r = 1.2, // radius of nodes
    padding = 3, // horizontal padding for first and last column
    fill = '#fff', // fill for nodes
    fillOpacity, // fill opacity for nodes
    stroke = '#fff', // stroke for links
    strokeWidth = 2, // stroke width for links
    strokeOpacity = 0.25, // stroke opacity for links
    strokeLinejoin, // stroke line join for links
    strokeLinecap, // stroke line cap for links
    halo = '#0F1519', // color of label halo
    haloWidth = 6, // padding around the labels
    onNodeClick,
    isMobile = false,
  } = {},
) {
  // If id and parentId options are specified, or the path option, use d3.stratify
  // to convert tabular data to a hierarchy; otherwise we assume that the data is
  // specified as an object {children} with nested objects (a.k.a. the “flare.json”
  // format), and use d3.hierarchy.
  function initRoot() {
    if (path != null) {
      return d3.stratify().path(path)(data);
    }

    if (id != null || parentId != null) {
      return d3.stratify().id(id).parentId(parentId)(data);
    }

    return d3.hierarchy(data, children);
  }

  const root = initRoot();

  // Compute labels and titles.
  const descendants = root.descendants();
  const L = label == null ? null : descendants.map((d) => label(d.data, d));

  // Sort the nodes.
  if (sort != null) root.sort(sort);

  // Compute the layout.
  const dx = 10;
  const dy = width / (root.height + padding);
  tree().nodeSize([20, dy])(root);

  // Center the tree.
  let x0 = Infinity;
  let x1 = -x0;
  root.each((d) => {
    if (d.x > x1) x1 = d.x;
    if (d.x < x0) x0 = d.x;
  });

  // Compute the default height.
  let containerHeight = height;
  if (height === undefined) containerHeight = x1 - x0 + dx * 2;

  const svg = d3
    .create('svg')
    .attr('viewBox', [(-dy * padding) / 2, x0 - dx, width, containerHeight])
    .attr('width', width)
    .attr('height', containerHeight)
    .attr('style', 'max-width: 100%; height: auto; height: intrinsic;')
    .attr('font-family', 'sans-serif')
    .attr('font-size', 14);

  svg
    .append('g')
    .attr('fill', 'none')
    .attr('stroke', stroke)
    .attr('stroke-opacity', strokeOpacity)
    .attr('stroke-linecap', strokeLinecap)
    .attr('stroke-linejoin', strokeLinejoin)
    .attr('stroke-width', strokeWidth)
    .selectAll('path')
    .data(root.links())
    .join('path')
    .attr(
      'd',
      d3
        .linkHorizontal()
        .x((d) => d.y)
        .y((d) => d.x),
    );

  const node = svg
    .append('g')
    .selectAll('a')
    .data(root.descendants())
    .join('a')
    .on('click', async (e, d) => {
      if (isMobile) return;

      const file = d.data.File;

      if (file === undefined) return;

      const c = findClass(d);

      const network = findNetwork(d);
      // const wallet = new Wallet();

      const lastConnectedWalletType = localStorage.getItem('lastConnectedWalletType');

      switch (lastConnectedWalletType) {
        case 'coinbase':
          await wallet.connectCoinbaseWallet();
          break;
        case 'metamask':
          await wallet.connectMetamaskWallet();
          break;
        case 'biport':
          await wallet.connectBiportWallet();
          break;
        default:
          break;
      }

      await wallet.setChain(parseInt(network.chainId, 10));

      localStorage.setItem('file', file);
      localStorage.setItem('category', c);

      // 현재 지갑의 네트워크와 이동할 네트워크가 같을 경우 tree.js 레벨에서 이동시켜주도록 처리
      if (wallet.provider?.networkVersion === network.chainId.toString()) {
        onNodeClick();
      }
    })
    .attr('transform', (d) => `translate(${d.y},${d.x})`);

  node
    .append('circle')
    .attr('fill', (d) => (d.children ? stroke : fill))
    .attr('r', r);

  if (title != null) node.append('title').text((d) => title(d.data, d));

  if (L)
    node
      .append('text')
      .attr('dy', '0.32em')
      .attr('x', (d) => (d.children ? -6 : 6))
      .attr('text-anchor', (d) => (d.children ? 'end' : 'start'))
      .text((d, i) => L[i])
      .call((text) => text.clone(true))
      .attr('fill', 'white')
      .attr('stroke', halo)
      .attr('stroke-width', haloWidth);

  svg.selectAll('path').attr('stroke', (d) => {
    let s = d.target?.data?.Stroke;

    if (s === undefined) s = getStroke(d);

    return s;
  });

  return svg.node();
}

export default Tree;
