import styled from 'styled-components';
import Color from 'styles/colors';

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  border: solid 1px ${Color.Border};
  border-left: 0;
  background-color: #121b1f;

  @media (max-width: 767px) {
    height: calc(100vh - 52px);
  }
`;

export default ContentContainer;
