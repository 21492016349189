import React from 'react';
import styled from 'styled-components';

import { IconStyle } from 'styles/iconStyle';
import getTokenInfoBySymbol from 'lib/getTokenInfoBySymbol';

const Container = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  font-size: 16px;
`;

type OptionScenarioProps = {
  title: string;
  symbol: string;
};

const OptionScenario: React.FC<OptionScenarioProps> = ({ title, symbol }: OptionScenarioProps) => {
  const token = getTokenInfoBySymbol(symbol);

  return (
    <Container>
      {title && <img src={token.icon} alt="" style={IconStyle} />}
      <div style={{ marginLeft: '16px', width: '170px' }}>{title}</div>
    </Container>
  );
};

export default OptionScenario;
