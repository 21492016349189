/* eslint-disable quotes */
import { MetaMaskInpageProvider } from '@metamask/providers';

import Networks from 'lib/networks';
import RpcError from 'types/rpcError';
import { RequestArguments } from '@metamask/providers/dist/BaseProvider';
import NativeCurrency from './nativeCurrency';
import { findCoinbase, findMetamask } from './findProvider';

class Wallet {
  public provider: MetaMaskInpageProvider = window.ethereum;

  private address = '';

  private chain = '';

  // instance function
  public initialize(): void {
    this.initializeProvider();
    this.initializeAddress();
    this.initializeChain();
  }

  public initializeProvider(): void {
    this.provider?.removeAllListeners();
  }

  public initializeAddress(): void {
    this.setAddress('');
  }

  public initializeChain(): void {
    this.setChain(0);
  }

  public async connectMetamaskWallet(): Promise<string> {
    if (!window.ethereum) {
      throw new Error('No metamask provider');
    }

    const metamask = findMetamask();
    if (metamask === undefined) return '';
    this.provider = metamask;

    // sync address
    const accounts = (await this.callRequestAccounts()) || [];
    const account = accounts[0] || '';
    this.setAddress(account);

    return account;
  }

  public async connectCoinbaseWallet(): Promise<string> {
    if (!window.ethereum) {
      throw new Error('No coinbase provider');
    }

    const coinbase = findCoinbase();
    if (coinbase === undefined) return '';
    this.provider = coinbase;

    // sync address
    const accounts = (await this.callRequestAccounts()) || [];
    const account = accounts[0] || '';
    this.setAddress(account);

    return account;
  }

  public async connectBiportWallet(): Promise<string> {
    if (!window.biport) {
      throw new Error('No biport provider');
    }

    this.provider = window.biport;

    // sync address
    const accounts = (await this.callRequestAccounts()) || [];
    const account = accounts[0] || '';
    this.setAddress(account);

    // sync chain
    const chain = await this.callChain();
    this.setChain(parseInt(chain, 10));

    return account;
  }

  public callRequestAccounts(): Promise<string[]> {
    return this.rawCall<string[]>({
      method: 'eth_requestAccounts',
      params: [],
    });
  }

  public callChain(): Promise<string> {
    return this.rawCall<string>({
      method: 'net_version',
      params: [],
    });
  }

  public callAddChain(
    chainId: string,
    chainName: string,
    rpcUrls: string[],
    blockExplorerUrls: string[] | undefined = undefined,
    nativeCurrency: NativeCurrency | undefined = undefined,
    iconUrls: string[] | undefined = undefined,
  ): Promise<null> {
    const params = [
      {
        chainId,
        chainName,
        nativeCurrency,
        rpcUrls,
        blockExplorerUrls,
        iconUrls,
      },
    ];

    return this.rawCall({
      method: 'wallet_addEthereumChain',
      params,
    });
  }

  public callGetTransactionReceipt(txHash: string): Promise<string | null> {
    return this.rawCall({ method: 'eth_getTransactionReceipt', params: [txHash] });
  }

  public async rawCall<T>(args: RequestArguments): Promise<T> {
    if (!this.provider) {
      throw new Error('No wallet connected');
    }

    const response = await this.provider?.request<T>(args);

    return response as T;
  }

  // getter
  public getAddress(): string {
    return this.address;
  }

  public getchain(): string {
    return this.chain;
  }

  // setter
  public setAddress(address: string): void {
    this.address = '';

    if (address && Wallet.isAddress(address)) {
      this.address = address;
    }
  }

  public async setChain(_chainId: number): Promise<number> {
    const chain = Networks.find((c) => c.chainId === _chainId);

    if (chain === undefined) {
      return -1;
    }

    const { chainId, chainHexId, name, rpcUrl, symbol } = chain;
    const nativeCurrency = new NativeCurrency(symbol, symbol);

    try {
      await this.provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainHexId }],
      });
    } catch (switchError: unknown) {
      // This error code indicates that the chain has not been added to MetaMask.
      if ((switchError as RpcError)?.code === 4902) {
        try {
          await this.provider.request({
            method: 'wallet_addEthereumChain',
            params: [{ chainId: chainHexId, chainName: name, rpcUrls: [rpcUrl], nativeCurrency }],
          });
        } catch (addError) {
          // handle "add" error
        }
      }
      // handle other "switch" errors
    }

    return chainId;
  }

  // class variable
  public static regexAddress = /^(0x)?[0-9a-fA-F]{40}$/i;

  // class function
  public static isAddress(address: string): boolean {
    return Wallet.regexAddress.test(address);
  }
}

const getWallet = async () => {
  const lastConnectedWalletType = localStorage.getItem('lastConnectedWalletType');
  const wallet = new Wallet();

  switch (lastConnectedWalletType) {
    case 'biport':
      await wallet.connectBiportWallet();
      break;
    case 'coinbase':
      await wallet.connectCoinbaseWallet();
      break;
    case 'metamask':
      await wallet.connectMetamaskWallet();
      break;
    default:
      break;
  }

  return wallet;
};

export default Wallet;
export { getWallet };
