import ReactGA from 'react-ga4';
import { UaEventOptions } from 'react-ga4/types/ga4';

type TrackEventParams = Omit<UaEventOptions, 'category' | 'action'>;

class GAService {
  private env: 'development' | 'production' | 'test';

  constructor() {
    if (!process.env.REACT_APP_GA_TRACKING_ID) {
      throw new Error('GA_TRACKING_ID must be provided.');
    }

    this.env = process.env.NODE_ENV;

    // if (this.isProduction) {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
    // }
  }

  private get isProduction() {
    return this.env === 'production';
  }

  private trackEvent(optionsOrName: UaEventOptions) {
    if (!this.isProduction) {
      console.log(optionsOrName);
      // return;
    }

    ReactGA.event(optionsOrName, optionsOrName);
  }

  public trackPageView(path: string) {
    const decodedPath = decodeURIComponent(path);

    if (!this.isProduction) {
      console.log(`Page Viewed: ${decodedPath}`);
      return;
    }

    ReactGA.pageview(decodedPath);
  }

  public trackTabClickEvent(optionsOrName: TrackEventParams) {
    this.trackEvent({ category: 'Tab', action: 'Tab button clicked', ...optionsOrName });
  }

  public trackRunClickEvent(optionsOrName: TrackEventParams) {
    this.trackEvent({ category: 'Run', action: 'Run button clicked', ...optionsOrName });
  }

  public trackSaveAndNextClickEvent(optionsOrName: TrackEventParams) {
    this.trackEvent({
      category: 'Save & Next',
      action: 'Save & next button clicked',
      ...optionsOrName,
    });
  }
}

const GA = new GAService();

export default GA;
