import { atom } from 'jotai';
import i18n from 'i18next';
import CoinGecko from 'coingecko-api';

import Asset from 'types/asset';
import HasChildMenu from 'types/hasChildMenu';
import Category from 'types/menu';
import Scenario from 'types/scenario';
import Network from 'types/network';
import defaultLanguage from 'lib/defaultLanguage';
import invokeMethodAsync from 'lib/invokeMethodAsync';
import NetworkMeta from 'types/networkMeta';
import BASE_URL from 'lib/baseUrl';

const languageBaseAtom = atom(localStorage.getItem('language') ?? defaultLanguage());

const languageAtom = atom(
  (get) => get(languageBaseAtom),
  (get, set, _arg) => {
    if (get(languageBaseAtom) === `${_arg}`) return;

    set(languageBaseAtom, `${_arg}`);

    i18n.changeLanguage(`${_arg}`);
    localStorage.setItem('language', `${_arg}`);

    (async () => {
      // if (get(languageBaseAtom) !== `${_arg}`) {
      await invokeMethodAsync('ToGoEvaluator', 'evaluator.setLanguage', `${_arg}`);
      // }
    })();
  },
);

languageAtom.onMount = (setAtom) => {
  setAtom(defaultLanguage());
};

const networkAtom = atom<Network | undefined>(undefined);

const networkMetasAtom = atom<NetworkMeta[]>([]);

networkMetasAtom.onMount = (setAtom) => {
  (async () => {
    try {
      const networksJson = (await (await fetch(`${BASE_URL}/Menu.json`)).json()) as NetworkMeta[];
      setAtom(networksJson);
    } catch (e) {
      console.error(e);
      setAtom([]);
    }
  })();
};

const showTabAtom = atom(true);

const tabIndexAtom = atom(0);

const categoriesAtom = atom<Category[]>([]);

const selectedCategoryAtom = atom<Category | undefined>(undefined);

const level1Atom = atom<HasChildMenu | undefined>(undefined);

const level2Atom = atom<HasChildMenu | undefined>(undefined);

const level3Atom = atom<HasChildMenu | undefined>(undefined);

const selectedScenarioAtom = atom<Scenario | undefined>(undefined);

const walletAddressAtom = atom<string>('');

const assetsAtom = atom<Asset[]>([]);

const connectedWalletTypeAtom = atom<'biport' | 'coinbase' | 'metamask' | undefined>(undefined);

export {
  languageAtom,
  networkAtom,
  showTabAtom,
  tabIndexAtom,
  categoriesAtom,
  selectedCategoryAtom,
  selectedScenarioAtom,
  assetsAtom,
  walletAddressAtom,
  level1Atom,
  level2Atom,
  level3Atom,
  networkMetasAtom,
  connectedWalletTypeAtom,
};
