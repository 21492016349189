/* eslint-disable quotes */
const resource = {
  translation: {
    sitemap: {
      showAllMenus: 'Show all scenarios',
    },
    walletStatus: {
      connectWallet: 'Connect Wallet',
      unsupportedNetwork: 'Unsupported network',
    },
    popup: {
      noWallet: {
        message: 'Wallet not installed.',
      },
      connectWallet: {
        title: 'Connect a wallet.\nIf you do not have a wallet, please install.',
      },
      noConnectedWallet: {
        message: 'Please connect to MetaMask / Biport / Coinbase wallet to start service.',
      },
      reset: {
        message: 'All steps are reset due to dismission of network or wallet address.',
      },
      account: {
        message: 'Your account has been changed. refresh the page',
      },
      klaytn: {
        title: '- Warning -',
        message:
          'Connection of Cypress network might not proceed for some users. In this case, please check information below and add Cypress network with MetaMask custom RPC settings.',
        name: 'Network name',
        rpc: 'New RPC URL',
        chainId: 'Chain ID',
        symbol: 'Symbol (Optional)',
        explore: 'Block explore URL (Optional)',
      },
      confirm: 'Confirm',
    },
    tab: {
      about: 'What is ChainRunner?',
    },
    landing: {
      title: 'The simplest way to optimize your DeFi investment. ',
      subtitle: `Take advantage of pre-built DeFi strategies in just one platform.
      Deposit, borrow, transfer, buy, leverage and more - all in one place`,
      about: 'What is ChainRunner?',
      popularScenarios: {
        title: 'Popular Scenarios',
        subtitle:
          'Take advantage of pre-built DeFi strategies in just one platform. Deposit, borrow, transfer, buy, leverage and more - all in one place.',
        migration: 'Migration',
        bifiX: 'BiFi X',
        tokenPurchase: 'Token Purchase',
      },
      pros: {
        pros1: {
          title: 'All DeFi and smart contracts in one place',
          subtitle: 'Don’t waste your time learning to use all DeFi. It’s all in ChainRunner Q.',
        },
        pros2: {
          title: 'Combine various DeFi actions at once',
          subtitle: 'All blockchain activities combined in one place.',
        },
        pros3: {
          title: 'Try the most detailed version of DeFi aggregator in the industry',
          subtitle:
            'ChainRunner Q can process the most trivial orders in smart contracts, and even let you add new DeFi scenarios in ChainRunner yourself!',
        },
      },
      faq: {
        title: 'FAQ',
        q1: 'What is ChainRunner?',
        a1: `ChainRunner is built for end-users to easily use blockchain and all of its services. It interconnects the universal blockchain ecosystem by bringing together all services into one platform. Our team wanted to make blockchain easy. We wanted not just the few with "knowledge", but everybody to have chances to try and learn how much they can benefit from blockchain technology. To make this happen, blockchain had to be easy for users. At the same time, users had to be provided with the best environment to access blockchain. This is why we created ChainRunner to be the engine to help both the users and blockchain together. ChainRunner engine proceeds complicated blockchain services users have to process FOR them. At the same time, ChainRunner engine grows smarter every day, by connecting more and better services together to help blockchain platforms accessible to more users. ChainRunner will grow to many different versions. The first version of ChainRunner will start with a "Q", and this version will start with making blockchain easy for you. On your computer browser, you can use various DeFi methods like a cookbook and process many different DeFi actions all together, without having to move to every DeFi and services one by one.`,
        q2: 'Why should I use ChainRunner Q?',
        a2: `ChainRunner Q is the only DeFi aggregator automating all the detailed orders. You can automate detailed requests in ChainRunner Q, which is something you cannot try in other DeFi aggregators. You can execute additional calculations in detail between smart contracts in ChainRunner Q like this example scenario:\n\n"Swap 1 ETH worth of BiFi tokens in SushiSwap -> Deposit **1/3** of the obtained tokens of BiFi-WETH Pool"\n\nNotice that you can specify the number of tokens you wish to swap before you go on to the next step(deposit). Instead of automating the case scenario as a whole, you can modify your DeFi activity in detail, or even create one DeFi scenario of your own (supported from the next version of ChainRunner).\n\nQ grows with the community within the Bifrost City ecosystem. In fact, ChainRunner is composed of a markdown format which can be easily written by users with a bit of coding knowledge. Q will continue to grow into a platform where you will be able to add new scenarios inside ChainRunner Q yourself and grow the platform smarter together.`,
        q3: `Can I save my gas fees with ChainRunner Q?`,
        a3: `ChainRunner Q can connect multiple smart contracts at once, but is not directly involved in the operation of the networks, meaning that ChainRunner is not involved in operating or lowering gas fees. In the status quo,  we recommend using the service on the BSC network, which provides gas costs  92% cheaper than the Ethereum network. `,
        q4: 'What DeFi platforms are supported in ChainRunner Q?',
        a4: `Currently, we support various DeFi positions using Compound, Aave V2, BiFi, BiFi X, and SushiSwap. Ultimately, we will gradually add more DeFi platforms and various scenarios to support alll platforms on the blockchain.`,
        q5: 'Are there any service fees?',
        a5: `Services starting with ChainRunner Q currently do not charge a subscription fee. Service fees will be charged with BFC tokens in the near future as the platform grows to the next level.`,
        q6: 'Is there any guides to using ChainRunner Q?',
        a6: 'You can follow video guides being uploaded in YouTube <a target="_blank" href="https://www.youtube.com/watch?v=h6m4x-CnMV8&list=PL2vuJd1qLwtCGDx--1sBTSc7BiNApEGc3">here</a>',
      },
    },
    about: {
      title: 'What is ChainRunner?',
      subtitle: `ChainRunner was built for end-users to easily use blockchain and all of its services. It interconnects the universal blockchain ecosystem by bringing together all the services into one platform. `,
      eq1: 'Financial Innovation: From the past to present',
      ea1: `Traditional finance has come a long way.\n\nWith digital innovation in fintech and its players' introduction of disruptive 'Killer Apps', finance and investing has become more accessible for the mass.`,
      q1: 'The Problem with Blockchain',
      a1: `Following trends in fintech, floods of products and services are entering the DeFi space as the market is still maturing.\n\nHowever, at the intersection of blockchain and traditional finance, users can easily become overwhelmed with different terms, interfaces, and multiple strategies to learn. To use innovative products and services like DeFi or NFTs, you need some financial knowledge, know how to use crypto wallets, or even learn coding skills to write smart contracts and harness their full potential. Familiarizing oneself with one DeFi platform alone is challenging and users are confined to one or a few DeFi services at best.\n\nBlockchain certainly opens up a new world - but only to those who fully understand it. It can be resource-exhaustive to become engaged in, or further, to enter this so-called *ecosystem*. This is where ChainRunner comes in.`,
      q2: 'ChainRunner: Blockchain made easy',
      a2: `ChainRunner combines multiple blockchain services like DeFi and provides users a simple and effortless way to use them in one unified platform. ChainRunner is the engine that runs complex blockchain services and integrates their features, and provides on-demand smart integration for specific strategies that users request.\nAs the engine running on top of multiple blockchain services, ChainRunner focuses on enhancing both user and service functionalities at its best to compete in the uncontested, ever-changing blockchain space. At its core, ChainRunner aims to spearhead the inclusive and sustainable development of all blockchain services. `,
      q3: 'How ChainRunner transforms DeFi space',
      a3: {
        p1: `With ChainRunner, users can readily execute complex DeFi strategies in a single, unified platform and thereby free themselves from manual processes to focus on what they really want such as the following: `,
        li1: 'Manage and use various DeFi services in one platform without the need of familiarizing oneself with each and every platform',
        li2: 'Easily compare profit opportunities across multiple DeFis to maximize total gains',
        li3: 'Customize and share unique trading scenarios by leveraging a combination of DeFi services for profit sharing',
        li4: 'Own and manage NFTs intuitively without technical understanding: create (mint), exchange (buy/sell), transfer ownership, and more',
        li5: 'Write and test smart contracts in a single development environment without utilizing multiple tools',
        p2: ``,
        li6: `<a target="_blank" href="https://chrome.google.com/webstore/detail/biport-wallet/mapbhaebnddapnmifbbkgeedkeplgjmf">Biport</a> + ChainRunner: You will use functions of various DeFi and NFT services directly in your Biport wallet. What ChainRunner can do, the wallet can do right away. With Biport's interface, you can deposit your asset into DeFi services.`,
        li7: 'ChainRunner + <a target="_blank" href="https://bifi.finance/">BiFi</a> or <a href="https://x.bifi.finance/">BiFi X</a>: Gone the boundaries with other platforms. The task of swapping tokens with Uniswap or Sushiswap to use BiFi or BiFi-X services can now be easily done through ChainRunner.',
      },
      q4: 'The first runner-up, ChainRunner Q',
      a4: `ChainRunner will be released in multiple stages into various versions.\n\n"Q" is the first runner-up, our all-rounded executive blockchain chef with handy automated DeFi recipes. On Q, you can "cook" different kinds of DeFi scenarios within a few clicks. While there are many aggregators out there, Q is the most flexible automated DeFi aggregator you can find with its pre-built recipes.\n\nFor example, Q performs all the functions within your order of the DeFi recipe: "Deposit liquidity pool (LP) tokens to BiFi-WETH pool" within entering the amount of WETH and a few clicks. If you want to "swap 1 ETH worth of BiFi tokens on SushiSwap and deposit the swapped tokens to BiFi-WETH pool on BiFi", normally, you would need to first swap the tokens, then deposit across two different DeFis. This is like running around different kitchens to cook one menu. With Q, you can do it all in one place by clicking on the recipe, and Q will be your sous-chef to take charge of the rest.`,
      q5: 'BIFROST Ecosystem Expands with ChainRunner',
      a5: {
        p1: 'ChainRunner Q is the game-changer to make BIFROST and the entire blockchain ecosystem much more self-sustainable and superior.',
        li1: 'Biport Wallet, with ChainRunner:\nWith the help of ChainRunner, users can use DeFi or NFT services directly within Biport wallet to stake assets.',
        li2: `BiFi & BiFi X, with ChainRunner:\nWith the help of ChainRunner, users don't have to go through Uniswap or SushiSwap to swap tokens and stake them in BiFi or BiFi X.`,
        p2: 'Anyone with coding skills and Markdown knowledge is welcome to contribute to making Q smarter, thereby becoming pioneers of this exciting space. Moving forward, ChainRunner Q will integrate any kind of DeFi recipes.\n\nWe envision this platform to grow into a permissionless, profit-sharing automated recipe book where contributors can share their unique strategies to a wider range of audience and together disrupt the blockchain space.',
      },
      learnMore: `Learn about ChainRunner's distinct technical features in the <a target="_blank" href="https://medium.com/bifrost/chainrunner-technical-report-f6aaa98bd17b?source=friends_link&sk=70b4b8e8b6a0c390ee1decf85d284d2c">Technical Report</a>.`,
    },
    dex: {
      error: {
        '-1': 'Internal Error',
        10100: 'Bridging 하려는 값이 0보다 작거나 같음',
        10101: 'Bridge service에서 규정한 최소량보다 bridge 하려는 값이 작을 경우',
        10102: 'Bridge service에서 규정한 최대량보다 bridge 하려는 값이 클 경우',
        10103:
          '사용자가 소유한 bridging하려는 asset의 총량이 부족함 (bridging하려는 값(AmountIn)보다 적음)',
        10104:
          '사용자가 소유한 bridging하려는 asset의 총량이 부족함 (bridging하려는 값(AmountIn)보다 적음)',
        10200: 'Bridge 하려는 asset의 Symbol를 asset 목록에서 찾을 수 없음',
        10201: 'Bridge 하려는 asset을 현재 chain에서 이용할 수 없음',
        10202: 'Bridge 하려는 asset을 bridge 대상 network로 bridge할 수 없음',
        10203: 'Bridge 하려는 asset의 정보를 asset 목록에서 찾을 수 없음',
        10300: 'Bridge 대상 network의 chain ID를 network 목록에서 찾을 수 없음',
        10400: 'Bridge 하려는 asset의 pair를 지원해주는 DEX를 찾을 수 없음',
        20100: 'Swap하려는 값이 0보다 작거나 같음',
        20101:
          '사용자가 소유한 swap하려는 asset의 총량이 부족함 (swap하려는 값(AmountIn)보다 적음)',
        20102: '비정상적인 amount 값',
        20200: 'Swap 하려는 asset의 Symbol를 asset 목록에서 찾을 수 없음',
        20201: 'Swap 하려는 asset을 현재 chain에서 이용할 수 없음',
        20202: 'Swap 하려는 asset의 정보를 asset 목록에서 찾을 수 없음',
        20300: 'Swap 대상 asset의 Symbol를 asset 목록에서 찾을 수 없음',
        20301: 'Swap 대상 asset을 현재 chain에서 이용할 수 없음',
        20400: 'Swap하려는 asset의 pair를 swap해주는 DEX를 찾을 수 없음',
        30100: 'Swap 하려는 값이 0보다 작거나 같음',
        30101: 'Swap 하려는 asset의 양이 bridge service에서 규정한 최소량보다 적을 경우',
        30102: 'Swap 하려는 asset의 양이 bridge service에서 규정한 최소량보다 많을 경우',
        30103:
          '사용자가 소유한 swap하려는 asset의 총량이 부족함 (swap하려는 값(AmountIn)보다 적음)',
        30104: '비정상적인 amount 값',
        30200: 'Swap 하려는 asset의 ID을 asset 목록에서 찾을 수 없음',
        30201: 'Swap 하려는 asset을 현재 chain에서 이용할 수 없음',
        30202: 'Swap 하려는 asset을 swap의 대상 network으로 bridging할 수 없음',
        30203: 'Swap 하려는 asset의 정보를 asset 목록에서 찾을 수 없음',
        30204: 'Swap 하려는 asset의 bridge 대상 asset의 정보를 asset 목록에서 찾을 수 없음',
        30300: 'Swap의 대상 network의 chain ID를 network 목록에서 찾을 수 없음',
        30301: 'Swap의 대상 asset을 swap의 대상 network에서 이용할 수 없음',
        30302: 'Swap의 대상 asset의 정보를 asset 목록에서 찾을 수 없음',
        30400: 'Swap의 대상 network의 chain ID를 network 목록에서 찾을 수 없음',
        30500:
          'Swap 하려는 asset의 pair에 대해 swap해주는 DEX를 swap의 대상 network에서 찾을 수 없음',
      },
    },
  },
};

export default resource;
