import React, { useMemo } from 'react';
import styled from 'styled-components';
import StepState from 'types/stepState';
import Color from 'styles/colors';
import StepStatus from './stepStatus';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StepNumber = styled.div`
  min-width: 70px;
  font-size: 16px;
  font-weight: bold;
  color: ${Color.Header};
`;

const Title = styled(StepNumber)`
  line-height: 16px;
  margin-left: 0px;
  margin-right: 14px;
`;

type StepHeaderProps = {
  stepIndex: number;
  title: string;
  isFinishStep: boolean;
};

const StepHeader: React.FC<StepHeaderProps> = ({
  stepIndex,
  title,
  isFinishStep,
}: StepHeaderProps) => {
  const stepNumberTitle = useMemo(() => {
    return isFinishStep ? 'Fin.' : `Step ${stepIndex}.`;
  }, [isFinishStep]);

  return (
    <Container>
      <StepNumber>{stepNumberTitle}</StepNumber>
      <Title>{title}</Title>
    </Container>
  );
};

export default StepHeader;
