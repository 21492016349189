import React from 'react';
import { useAtom } from 'jotai';

import { loadingWalletAtom } from 'states/walletStore';
import { getNetworkInfoByChainId } from 'lib/getNetworkInfo';
import invokeMethodAsync from 'lib/invokeMethodAsync';
import {
  walletAddressAtom,
  languageAtom,
  networkAtom,
  connectedWalletTypeAtom,
} from 'states/mainStore';
import ConnectWalletIdentifier from 'types/connectWalletIdentifier';
import WalletType from 'types/walletType';
import EvaluatorResponse from 'types/evaluatorResponse';
import useInvalidKlaytnRpcPopup from './useInvalidKlaytnRpcPopup';

const useConnectWallet = (
  methodIdentifier: ConnectWalletIdentifier,
  lastConnectedWalletType: WalletType,
) => {
  const [language] = useAtom(languageAtom);
  const [, setNetwork] = useAtom(networkAtom);
  const [, setWalletAddress] = useAtom(walletAddressAtom);
  const [, setLoadingWallet] = useAtom(loadingWalletAtom);
  const [, setConnectedWalletType] = useAtom(connectedWalletTypeAtom);
  const [showRpcPopup] = useInvalidKlaytnRpcPopup();

  const connectWallet = async () => {
    setLoadingWallet(true);
    let res: EvaluatorResponse & { Account: string; Network: number } = {
      Ok: false,
      Account: '',
      Network: 0,
    };

    try {
      res = await invokeMethodAsync<{ Account: string; Network: number }>(
        'ToGoEvaluator',
        methodIdentifier,
      );
    } catch (e) {
      setLoadingWallet(false);
    }

    if (res.Ok && res.Account) {
      setWalletAddress(res.Account);
      const newNetwork = getNetworkInfoByChainId(res.Network);
      setNetwork(newNetwork);

      localStorage.setItem('lastConnectedWalletType', lastConnectedWalletType);
      setConnectedWalletType(lastConnectedWalletType);
      // await invokeMethodAsync('ToGoEvaluator', 'evaluator.setLanguage', language);

      // if (newNetwork?.chainId === 8217) {
      //   const availableRes = await invokeMethodAsync(
      //     'ToGoEvaluator',
      //     'evaluator.isCypressNodeAvailable',
      //   );
      //   if (!availableRes.Ok) {
      //     showRpcPopup();
      //   }
      // }
    }
    setLoadingWallet(false);
  };

  return connectWallet;
};

export default useConnectWallet;
