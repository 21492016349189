import React from 'react';
import styled from 'styled-components';
import { usePopup } from 'react-hook-popup';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from 'assets/close.svg';
import DimmedBackground from 'components/common/dimmedBackground';
import PopupBackground from 'components/common/popupBackground';
import Confirm from 'components/common/confirm';

const Title = styled.div`
  color: #f9b10b;
  text-align: center;
  width: 100%;
  font-size: 20px;
`;

const RpcContainer = styled.div`
  margin: 30px 0 50px 0;
`;

const RpcTitle = styled.div`
  color: rgba(255, 255, 255, 0.35);
  margin-top: 12px;
`;

const RpcContent = styled.div`
  margin-top: 12px;
`;

const useInvalidKlaytnRpcPopup = () => {
  const { t } = useTranslation();

  const [showPopup, hidePopup] = usePopup('useInvalidKlaytnRpcPopup', () => (
    <DimmedBackground>
      <PopupBackground style={{ height: 'initial' }}>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <CloseIcon
            onClick={() => hidePopup()}
            style={{ width: '24px', height: '24px', cursor: 'pointer' }}
          />
        </div>
        <Title>{t('popup.klaytn.title')}</Title>
        <div style={{ margin: '20px 40px 0 40px', textAlign: 'center' }}>
          {t('popup.klaytn.message')}
        </div>
        <RpcContainer>
          <RpcTitle>{t('popup.klaytn.name')}</RpcTitle>
          <RpcContent>Cypress - bifrost</RpcContent>
          <RpcTitle>{t('popup.klaytn.rpc')}</RpcTitle>
          <RpcContent>https://cypress.chain.thebifrost.io</RpcContent>
          <RpcTitle>{t('popup.klaytn.chainId')}</RpcTitle>
          <RpcContent>8217</RpcContent>
          <RpcTitle>{t('popup.klaytn.symbol')}</RpcTitle>
          <RpcContent>KLAY</RpcContent>
          <RpcTitle>{t('popup.klaytn.explore')}</RpcTitle>
          <RpcContent>https://scope.klaytn.com/</RpcContent>
        </RpcContainer>
        <Confirm onClick={() => hidePopup()}>{t('popup.confirm')}</Confirm>
      </PopupBackground>
    </DimmedBackground>
  ));

  return [showPopup, hidePopup];
};

export default useInvalidKlaytnRpcPopup;
