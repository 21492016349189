import { MetaMaskInpageProvider } from '@metamask/providers';

const findCoinbase = (): MetaMaskInpageProvider | undefined => {
  const { ethereum } = window;

  if (ethereum?.providers) {
    return ethereum?.providers?.find((p) => p.isCoinbaseWallet);
  }

  if (ethereum?.isCoinbaseWallet) {
    return ethereum;
  }

  return undefined;
};

const findMetamask = (): MetaMaskInpageProvider | undefined => {
  const { ethereum } = window;

  if (ethereum?.isMetaMask) {
    if (ethereum?.providers) {
      return ethereum?.providers?.find((p) => p.isMetaMask);
    }

    return ethereum;
  }

  return undefined;
};

export { findCoinbase, findMetamask };
