import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useAtom } from 'jotai';

import StepOutput from 'types/stepOutput';
import invokeMethodAsync from 'lib/invokeMethodAsync';
import StepStore from 'states/stepStore';
import { currentStepAtom } from 'states/scenarioStore';
import CodeBox from './codeBox';

const InputContainer = styled(CodeBox)`
  width: 100%;
  margin-top: 16px;
  background: transparent;
  border: 0;

  & * {
    white-space: pre-wrap;
  }
`;

type WithoutInputProps = {
  stepStore: StepStore;
  stepOutput: StepOutput;
  stepIndex: number;
};

const WithoutInput: React.FC<WithoutInputProps> = ({
  stepStore,
  stepOutput,
  stepIndex,
}: WithoutInputProps) => {
  const [currentStep] = useAtom(currentStepAtom);
  const [codeOutput] = useAtom(stepStore.codeOutputAtom);
  const [codeError] = useAtom(stepStore.codeErrorAtom);
  const [, setCode] = useAtom(stepStore.codeAtom);

  useEffect(() => {
    if (currentStep === stepIndex) setCode(stepOutput.Code);
  }, [currentStep]);

  return (
    <InputContainer>
      {codeOutput && <div>{codeOutput}</div>}
      {codeError && <div style={{ color: 'red' }}>{codeError}</div>}
    </InputContainer>
  );
};

export default WithoutInput;
