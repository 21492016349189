import Network from 'types/network';

const Networks: Network[] = [
  new Network(
    '0x1',
    false,
    '#3bb5ae',
    'Ethereum mainnet',
    'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    'https://etherscan.io',
  ),
  new Network(
    '0x539',
    false,
    '#3bb5ae',
    'Ethereum mainnet',
    'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
  ),
  new Network(
    '0x3',
    false,
    '#fb508e',
    'Ropsten Testnet',
    'https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    'https://ropsten.etherscan.io',
  ),
  new Network(
    '0x4',
    false,
    '#f4c150',
    'Rinkeby Testnet',
    'https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    'https://rinkeby.etherscan.io',
  ),
  new Network(
    '0x5',
    false,
    '#379cef',
    'Goerli Testnet',
    'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    'https://goerli.etherscan.io',
  ),
  new Network(
    '0x2a',
    false,
    '#8c6cfb',
    'Kovan Testnet',
    'https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    'https://kovan.etherscan.io',
  ),
  new Network(
    '0x38',
    true,
    '#f9b10b',
    'BSC mainnet',
    'https://bsc-dataseed.binance.org/',
    'https://bscscan.com/',
    'BNB',
  ),
  new Network(
    '0x61',
    false,
    '#f9b10b',
    'Binance Testnet',
    'https://data-seed-prebsc-1-s2.binance.org:8545/',
    '',
    'BNB',
  ),
  new Network(
    '0xa86a',
    false,
    '#f9b10b',
    'Avalanche mainnet',
    'https://api.avax.network/ext/bc/C/rpc',
    'https://snowtrace.io/',
    'AVAX',
  ),
  new Network(
    '0xa869',
    false,
    '#f9b10b',
    'Avalanche Testnet',
    'https://api.avax-test.network/ext/bc/C/rpc',
    '',
    'AVAX',
  ),
  new Network(
    '0x2019',
    false,
    '#f9b10b',
    'Klaytn mainnet (Cypress)',
    'https://cypress.chain.thebifrost.io',
    'https://scope.klaytn.com',
    'KLAYTN',
  ),
  new Network('0x1228', false, '#5600e4', 'PiLab Testnet', 'http://192.168.0.195:8500'),
];

export default Networks;
