import DefaultImage from 'assets/icon-40-px-default.svg';
import Token from 'types/token';
import Tokens from './tokens';

const getTokenInfoBySymbol = (symbol: string): Token => {
  if (!symbol) {
    return {
      symbol: '',
      icon: DefaultImage,
    };
  }

  const token = Tokens.find((x) => x.symbol.toUpperCase() === symbol.toUpperCase());

  if (token === undefined) {
    return {
      symbol: '',
      icon: DefaultImage,
    };
  }

  return token;
};

export default getTokenInfoBySymbol;
