import Token from 'types/token';
import AAVE from 'assets/icon-40-px-aave-logo.png';
import BFC from 'assets/icon-40-px-bfc.svg';
import AVAX from 'assets/icon-40-px-avax.svg';
import BENQI from 'assets/icon-40-px-benqi-logo.svg';
import BFCETH from 'assets/icon-40-px-bfc-eth.svg';
import BIFI from 'assets/icon-40-px-bi-fi-logo.svg';
import BIFIETH from 'assets/icon-40-px-bi-fi-eth.svg';
import BIFIT from 'assets/icon-40-px-bi-fi.png';
import BiFiX from 'assets/icon-40-px-bi-fi-x-logo.svg';
import BNB from 'assets/icon-40-px-bnb.svg';
import BNBUSDT from 'assets/icon-40-px-bnb-usdt.svg';
import BTC from 'assets/icon-40-px-btc.svg';
import COMP from 'assets/icon-40-px-compound-logo.svg';
import DAI from 'assets/icon-40-px-dai.svg';
import DOT from 'assets/icon-40-px-dot-white.svg';
import ETH from 'assets/icon-40-px-eth.svg';
import KLAY from 'assets/icon-40-px-klaytn.svg';
import KLAYSWAP from 'assets/icon-40-px-klayswap-logo.svg';
import LINK from 'assets/icon-40-px-link.svg';
import PANCAKE from 'assets/icon-40-px-pancake-logo.svg';
import PANGOLIN from 'assets/icon-40-px-pangolin-logo.svg';
import SUSHI from 'assets/icon-40-px-sushi-logo.svg';
import USDC from 'assets/icon-40-px-usdc.svg';
import USDT from 'assets/icon-40-px-usdt.svg';
import VENUS from 'assets/icon-40-px-venus-logo.svg';
import WBTC from 'assets/icon-40-px-wbtc.svg';
import XRP from 'assets/icon-40-px-xrp.svg';
import ANYSWAP from 'assets/icon-40-px-anyswap-logo.svg';
import CLAIMSWAP from 'assets/icon-40-px-claimswap-logo.svg';
import DEFISWAP from 'assets/icon-40-px-defiswap-logo.svg';
import PARTYSWAP from 'assets/icon-40-px-partyswap-logo.svg';
import UNISWAP from 'assets/icon-40-px-uniswap-logo.svg';

const Tokens: Token[] = [
  {
    symbol: 'AVAX',
    icon: AVAX,
    tokenId: 'avax_avax_0',
  },
  {
    symbol: 'AVAX',
    icon: AVAX,
    tokenId: 'bscmain_avax_0',
  },
  {
    symbol: 'WAVAX',
    icon: AVAX,
  },
  {
    symbol: 'AAVE',
    icon: AAVE,
    tokenId: 'aave',
  },
  {
    symbol: 'BENQI',
    icon: BENQI,
    tokenId: 'benqi',
  },
  {
    symbol: 'BFC',
    icon: BFC,
    tokenId: 'eth_bfc',
  },
  {
    symbol: 'BFC',
    icon: BFC,
    tokenId: 'bscmain_bfc_0',
  },
  {
    symbol: 'BFC',
    icon: BFC,
    tokenId: 'cypress_bfck_0',
  },
  {
    symbol: 'BFC-ETH',
    icon: BFCETH,
  },
  {
    symbol: 'BiFi',
    icon: BIFIT,
    tokenId: 'eth_bifi_0',
  },
  {
    symbol: 'BiFi',
    icon: BIFIT,
    tokenId: 'avax_bifi_0',
  },
  {
    symbol: 'BiFi',
    icon: BIFIT,
    tokenId: 'bscmain_bifi_0',
  },
  {
    symbol: 'BIFI',
    icon: BIFI,
    tokenId: 'bifi',
  },
  {
    symbol: 'BIFI-ETH',
    icon: BIFIETH,
  },
  {
    symbol: 'BIFIT',
    icon: BIFIT,
  },
  {
    symbol: 'BIFIB',
    icon: BIFIT,
  },
  {
    symbol: 'BIFIX',
    icon: BiFiX,
    tokenId: 'bifix',
  },
  {
    symbol: 'BNB',
    icon: BNB,
    tokenId: 'bscmain_bnb_0',
  },
  {
    symbol: 'BNB',
    icon: BNB,
    tokenId: 'eth_bnb_0',
  },
  {
    symbol: 'WBNB',
    icon: BNB,
  },
  {
    symbol: 'BNB-USDT',
    icon: BNBUSDT,
  },
  {
    symbol: 'BTC',
    icon: BTC,
  },
  {
    symbol: 'BTCB',
    icon: BTC,
    tokenId: 'bscmain_btcb_0',
  },
  {
    symbol: 'COMPOUND',
    icon: COMP,
    tokenId: 'compound',
  },
  {
    symbol: 'DAI',
    icon: DAI,
    tokenId: 'eth_dai_0',
  },
  {
    symbol: 'DAI',
    icon: DAI,
    tokenId: 'bscmain_dai_0',
  },
  {
    symbol: 'DAI',
    icon: DAI,
    tokenId: 'avax_dai_0',
  },
  {
    symbol: 'DAI.e',
    icon: DAI,
  },
  {
    symbol: 'DOT',
    icon: DOT,
  },
  {
    symbol: 'ETH',
    icon: ETH,
    tokenId: 'eth_eth_0',
  },
  {
    symbol: 'ETH',
    icon: ETH,
    tokenId: 'bscmain_eth_0',
  },
  {
    symbol: 'oBiFi',
    icon: BIFIT,
    tokenId: 'cypress_kbifi_0',
  },
  {
    symbol: 'KDAI',
    icon: DAI,
    tokenId: 'cypress_dai_0',
  },
  {
    symbol: 'oETH',
    icon: ETH,
    tokenId: 'cypress_weth_0',
  },
  {
    symbol: 'KLAY',
    icon: KLAY,
    tokenId: 'cypress_klay_0',
  },
  {
    symbol: 'KLAYSWAP',
    icon: KLAYSWAP,
    tokenId: 'klay',
  },
  {
    symbol: 'KSP',
    icon: KLAYSWAP,
    tokenId: 'cypress_ksp_0',
  },
  {
    symbol: 'oUSDC',
    icon: USDC,
    tokenId: 'cypress_usdc_0',
  },
  {
    symbol: 'oUSDT',
    icon: USDT,
    tokenId: 'cypress_usdt_0',
  },
  {
    symbol: 'oWBTC',
    icon: WBTC,
    tokenId: 'cypress_wbtc_0',
  },
  {
    symbol: 'oXRP',
    icon: XRP,
    tokenId: 'cypress_xrp_0',
  },
  {
    symbol: 'LINK',
    icon: LINK,
    tokenId: 'eth_link_0',
  },
  {
    symbol: 'LINK',
    icon: LINK,
    tokenId: 'bscmain_link_0',
  },
  {
    symbol: 'LINK',
    icon: LINK,
    tokenId: 'avax_link_0',
  },
  {
    symbol: 'LINK.e',
    icon: LINK,
  },
  {
    symbol: 'PANCAKESWAP',
    icon: PANCAKE,
    tokenId: 'pancake',
  },
  {
    symbol: 'PANGOLIN',
    icon: PANGOLIN,
    tokenId: 'pangolin',
  },
  {
    symbol: 'SUSHISWAP',
    icon: SUSHI,
    tokenId: 'sushiswap',
  },
  {
    symbol: 'USDC',
    icon: USDC,
    tokenId: 'avax_usdc_1',
  },
  {
    symbol: 'USDC',
    icon: USDC,
    tokenId: 'bscmain_usdc_0',
  },
  {
    symbol: 'USDC',
    icon: USDC,
    tokenId: 'eth_usdc_0',
  },
  {
    symbol: 'USDC.e',
    icon: USDC,
    tokenId: 'avax_usdc_0',
  },
  {
    symbol: 'USDT',
    icon: USDT,
    tokenId: 'avax_usdt_1',
  },
  {
    symbol: 'USDT',
    icon: USDT,
    tokenId: 'eth_usdt_0',
  },
  {
    symbol: 'USDT.e',
    icon: USDT,
    tokenId: 'avax_usdt_0',
  },
  {
    symbol: 'VENUS',
    icon: VENUS,
    tokenId: 'venus',
  },
  {
    symbol: 'WBTC',
    icon: WBTC,
    tokenId: 'eth_wbtc_0',
  },
  {
    symbol: 'WBTC.e',
    icon: WBTC,
    tokenId: 'avax_wbtce',
  },
  {
    symbol: 'WETH.e',
    icon: ETH,
    tokenId: 'avax_weth_0',
  },
  {
    symbol: 'WETH',
    icon: ETH,
  },
  {
    symbol: 'XRP',
    icon: XRP,
    tokenId: 'bscmain_xrp_0',
  },
  {
    symbol: 'AnySwap',
    icon: ANYSWAP,
    tokenId: 'anyswap',
  },
  {
    symbol: 'ClaimSwap',
    icon: CLAIMSWAP,
    tokenId: 'claim',
  },
  {
    symbol: 'DeFiSwap',
    icon: DEFISWAP,
    tokenId: 'defiswap',
  },
  {
    symbol: 'PartySwap',
    icon: PARTYSWAP,
    tokenId: 'party',
  },
  {
    symbol: 'UniSwap',
    icon: UNISWAP,
    tokenId: 'uniswap',
  },
  {
    symbol: 'UniSwapV2',
    icon: UNISWAP,
    tokenId: 'uniswapv2',
  },
  {
    symbol: 'Ethereum',
    icon: ETH,
    chainId: 1,
  },
  {
    symbol: 'Binance',
    icon: BNB,
    chainId: 56,
  },
  {
    symbol: 'Klaytn',
    icon: KLAY,
    chainId: 8217,
  },
  {
    symbol: 'Avalanche',
    icon: AVAX,
    chainId: 43114,
  },
];

export default Tokens;
