import React from 'react';
import { usePopup } from 'react-hook-popup';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from 'assets/close.svg';
import DimmedBackground from 'components/common/dimmedBackground';
import PopupBackground from 'components/common/popupBackground';
import QRCode from 'components/main/connect/qrcode';
import InstallWallet from 'components/main/connect/installWallet';

const useInstallWalletPopup = (QRCodeImageUrl: string, installUrl: string) => {
  const { t } = useTranslation();

  const [showPopup, hidePopup] = usePopup(QRCodeImageUrl, () => (
    <DimmedBackground>
      <PopupBackground style={{ height: 'initial' }}>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <CloseIcon
            onClick={() => hidePopup()}
            style={{ width: '24px', height: '24px', cursor: 'pointer' }}
          />
        </div>
        <div
          style={{
            margin: '12px 40px',
            textAlign: 'center',
            fontSize: '18px',
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <div>{t('popup.noWallet.message')}</div>
          <InstallWallet index={0} url={installUrl} />
        </div>
        <QRCode src={QRCodeImageUrl} />
      </PopupBackground>
    </DimmedBackground>
  ));

  return [showPopup, hidePopup];
};

export default useInstallWalletPopup;
