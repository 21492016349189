import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from 'reportWebVitals';
import { datadogRum } from '@datadog/browser-rum';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PopupProvider } from 'react-hook-popup';

import GA from 'lib/gaService';
import './index.css';
import Main from 'components/main/main';
import GlobalStyle from 'styles/global';
import SiteMap from 'components/sitemap/sitemap';

import 'i18n/index';

window.onerror = (ev) => {
  alert(ev);
};

datadogRum.init({
  applicationId: '239d3740-7f1f-4b06-aa42-001f081c6edb',
  clientToken: 'pub890153806fb61888de630050e3cf30f5',
  site: 'datadoghq.com',
  service: 'chainrunner-q',
  env: 'prod',
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
});

datadogRum.startSessionReplayRecording();

GA.trackPageView(window.location.pathname);

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <PopupProvider>
      <BrowserRouter>
        <Routes>
          <Route path="sitemap" element={<SiteMap />} />
          <Route path="/*" element={<Main />} />
          <Route path="*" element={<Main />} />
        </Routes>
      </BrowserRouter>
    </PopupProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
