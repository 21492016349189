import styled from 'styled-components';

const HeaderRightContainer = styled.div`
  display: flex;
  flex-direction: row;

  & > * {
    margin-left: 10px;
  }
`;

export default HeaderRightContainer;
