const IconStyle: React.CSSProperties = {
  height: '32px',
  minWidth: '40px',
  maxWidth: '72px',
  objectFit: 'contain',
};

const SmallIconStyle: React.CSSProperties = {
  height: '24px',
  minWidth: '40px',
  objectFit: 'contain',
};

export { IconStyle, SmallIconStyle };
