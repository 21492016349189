import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useAtom } from 'jotai';

import useIsMobile from 'hooks/useIsMobile';
import { ReactComponent as CheckIcon } from 'assets/check.svg';
import { ReactComponent as RefreshIcon } from 'assets/refresh.svg';
import { stepCountAtom, currentStepAtom } from 'states/scenarioStore';
import { selectedScenarioAtom } from 'states/mainStore';

const Container = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.1);
  width: 100%;
  height: ${({ isMobile }) => (isMobile ? 'initial' : '40px')};
  padding: ${({ isMobile }) => (isMobile ? '6px 20px' : '0 30px')};
  gap: 10px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const RefreshContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin-left: 16px;
  border-radius: 3px;
  background-color: #238fc8;
  cursor: pointer;
`;

type ScenarioHeaderProps = {
  title: string;
};

const ScenarioHeader: React.FC<ScenarioHeaderProps> = ({ title }: ScenarioHeaderProps) => {
  const [currentStep] = useAtom(currentStepAtom);
  const [stepCount] = useAtom(stepCountAtom);

  const isMobile = useIsMobile();

  return (
    <Container isMobile={isMobile}>
      <Title>{title}</Title>
      <RightContainer>
        <ProgressContainer>
          <div style={{ minWidth: '40px' }}>{`${currentStep} / ${stepCount}`}</div>
          <CheckIcon style={{ margin: 'auto 0 auto 8px' }} />
        </ProgressContainer>
        {/* <RefreshContainer onClick={() => setResetRequest(true)}>
          <RefreshIcon />
        </RefreshContainer> */}
      </RightContainer>
    </Container>
  );
};

export default ScenarioHeader;
