import { useCallback } from 'react';

import ConnectWalletIdentifier from 'types/connectWalletIdentifier';
import WalletType from 'types/walletType';
import useIsMobile from './useIsMobile';
import useConnectWallet from './useConnectWallet';
import useChainrunnerPopup from './useChainrunnerPopup';

const useAccountChanged = (
  methodIdentifier: ConnectWalletIdentifier,
  lastConnectedWalletType: WalletType,
) => {
  const isMobile = useIsMobile();
  const connectWallet = useConnectWallet(methodIdentifier, lastConnectedWalletType);
  const [showAccountChangedPopup] = useChainrunnerPopup('popup.account.message');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const accountChanged = useCallback(async (...[accounts]: unknown[]) => {
    const c = localStorage.getItem('lastConnectedWalletType');
    if (isMobile && c !== null) {
      showAccountChangedPopup();
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
    await connectWallet();
  }, []);

  return accountChanged;
};

export default useAccountChanged;
