import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import BASE_URL from 'lib/baseUrl';

const MarkdownStyle = styled.div`
  font-size: 1rem;
  line-height: 2.5rem;

  img {
    max-width: 100%;
  }
`;

type MdContainerProps = {
  markdown: string;
};

const MdContainer: React.FC<MdContainerProps> = ({ markdown }: MdContainerProps) => {
  const pattern = /\[(?<title>.+)\]\((?<url>.*)\)/;
  const [[, title, url]] = useState(pattern.exec(markdown) || []);
  const baseUrl = useMemo(() => (url?.startsWith('http') ? '' : BASE_URL), [url]);

  return (
    <MarkdownStyle>
      <ReactMarkdown linkTarget="_blank">
        {markdown.replace(pattern, `[${title}](${baseUrl}${url})`)}
      </ReactMarkdown>
    </MarkdownStyle>
  );
};

export default MdContainer;
