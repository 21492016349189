import React from 'react';
import styled from 'styled-components';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';

import { languageAtom, selectedCategoryAtom, selectedScenarioAtom } from 'states/mainStore';
import Color from 'styles/colors';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  width: 100%;
  height: 44px;
  border-top: 1px solid ${Color.Border};
`;

const Lang = styled.select`
  outline: 0;
  border: 0;
  background-color: transparent;
  color: white;

  & > option {
    background-color: #16121f;
    outline: 0;
  }
`;

const Footer = () => {
  const navigate = useNavigate();
  const [language, setLanguage] = useAtom(languageAtom);
  const [, setSelectedCategory] = useAtom(selectedCategoryAtom);
  const [, setSelectedScenario] = useAtom(selectedScenarioAtom);

  const languageChange: React.ChangeEventHandler<HTMLSelectElement> = (e): void => {
    navigate('/home');
    const newValue = e.target.value;
    setLanguage(newValue);
    setSelectedCategory(undefined);
    setSelectedScenario(undefined);
  };

  return (
    <Container>
      <Lang
        className="App-Language"
        onChange={languageChange}
        onBlur={() => undefined}
        value={language}>
        <option value="en">English</option>
        <option value="kr">한국어</option>
        {/* <option value="zh">简体中文</option> */}
      </Lang>
      {/* <Copyright>© 2021 Pi-lab.</Copyright> */}
    </Container>
  );
};

export default Footer;
