import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

const GlobalStyle = createGlobalStyle`
    ${normalize}

    html,
    body {
        overflow: hidden;
        background: #0f1519;
        color: white;
    }

    * {
        box-sizing: border-box;
    }

    a {
        color: #f9b10b;
        /* text-decoration: none; */
        &:hover {
            cursor: pointer;
        }
    }

    text {
        fill: white;
    }

    ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background: #ffffff7a;
        border-radius: 3px;
    }
`;

export default GlobalStyle;
