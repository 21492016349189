import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useAtom } from 'jotai';

import { selectedScenarioAtom } from 'states/mainStore';
import { currentStepAtom } from 'states/scenarioStore';
import { ReactComponent as ExpandIcon } from 'assets/expand.svg';
import useDebounce from 'hooks/useDebounce';
import invokeMethodAsync from 'lib/invokeMethodAsync';
import Color from 'styles/colors';
import StepState from 'types/stepState';
import Config from 'types/config';
import StepStore from 'states/stepStore';
import CodeBox from './codeBox';

const Container = styled.div`
  width: 100%;
  border: solid 1px ${Color.Border};
  border-radius: 3px;
  padding: 16px;
  margin-bottom: 4px;
  margin-top: 16px;
`;

const InputContainer = styled(CodeBox)<{ stepState: StepState }>`
  width: 300px;
  margin-top: 16px;
  border: ${(props) => (props.stepState === 'error' ? '2px solid red' : '0')};
`;

const Description = styled.div`
  font-size: 14px;
`;

const InputField = styled.input<{ enabled: boolean }>`
  border: 0;
  background-color: transparent;
  outline: 0;
  color: white;
  pointer-events: ${(props) => (props.enabled ? 'inherit' : 'none')};
  opacity: ${(props) => (props.enabled ? '1' : '0.5')};
`;

const Symbol = styled.div`
  font-weight: bold;
`;

const ExpandContainer = styled.div`
  width: 300px;
  display: flex;
  justify-content: center;
  transform: rotate(180deg);
`;

type InputProps = {
  stepStore: StepStore;
  stepIndex: number;
  config: Config;
};

const exceptSymbols = ['%'];

const Input: React.FC<InputProps> = ({ stepStore, stepIndex, config }: InputProps) => {
  const [containsInput] = useState(config.Inputs.length > 0);
  const [containsOutput] = useState(config.Outputs.length > 0);

  const [{ Value, Label, Unit, Descs }] = useState(config.Inputs[0]);
  const [currentStep] = useAtom(currentStepAtom);
  const [selectedScenario] = useAtom(selectedScenarioAtom);
  const [debouncedValue, value, setValue] = useDebounce<string>(
    Value.replace(` ${Unit}`, ''),
    1000,
  );
  const [, setVerifyCode] = useAtom(stepStore.verifyCodeAtom);
  const [, setCode] = useAtom(stepStore.codeAtom);
  const [stepState, setStepState] = useAtom(stepStore.stepStateAtom);
  const [codeOutput] = useAtom(stepStore.codeOutputAtom);
  const [codeError, setCodeError] = useAtom(stepStore.codeErrorAtom);

  const generateCodeWithDebouncedValue = async () => {
    const unit = exceptSymbols.includes(Unit) ? '' : Unit;
    const verifyCode = config.Inputs.map((i) => i.VerifyCode).join('');
    setVerifyCode(`let ${Label} = ${debouncedValue} ${unit};${verifyCode}`);

    if (containsOutput) {
      setCode(
        `let ${Label} = ${debouncedValue} ${unit};${config.Outputs.map((o) => o.Code).join('')}`,
      );
    } else {
      setCode(`let ${Label} = ${debouncedValue} ${unit};`);
    }
  };

  const generateCodeWithValue = async () => {
    const unit = exceptSymbols.includes(Unit) ? '' : Unit;
    const verifyCode = config.Inputs.map((i) => i.VerifyCode).join('');
    setVerifyCode(`let ${Label} = ${value} ${unit};${verifyCode}`);

    if (containsOutput) {
      setCode(`let ${Label} = ${value} ${unit};${config.Outputs.map((o) => o.Code).join('')}`);
    } else {
      setCode(`let ${Label} = ${value} ${unit};`);
    }
  };

  const enabled = useMemo(() => {
    return stepIndex === currentStep && stepState !== 'loading';
  }, [currentStep, stepState]);

  useEffect(() => {
    generateCodeWithDebouncedValue();
  }, []);

  useEffect(() => {
    if ((debouncedValue.length === 0 || debouncedValue === '0') && codeOutput.length === 0) {
      setStepState('preparing');
      setCodeError('');
      return;
    }

    // dynamic input인 경우엔 기다려줘야함
    if (containsOutput) {
      generateCodeWithDebouncedValue();
      setStepState('done');
    }
  }, [debouncedValue, selectedScenario]);

  useEffect(() => {
    if ((value.length === 0 || value === '0') && codeOutput.length === 0) {
      setStepState('preparing');
      setCodeError('');
      return;
    }

    if (!containsOutput) generateCodeWithValue();
    // dynamic input이 아닌 경우엔 기다릴 필요가 없기 때문에 즉시 코드 생성
    else setStepState('loading');
  }, [value]);

  useEffect(() => {
    generateCodeWithDebouncedValue();
  }, [currentStep, stepState]);

  if (selectedScenario === undefined) return <></>;

  return (
    <Container>
      <Description>{Descs.join('')}</Description>
      {containsInput && (
        <InputContainer stepState={stepState}>
          <InputField
            enabled={enabled}
            placeholder={Label}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            onPaste={(e) => {
              setValue(e.clipboardData.getData('text'));
            }}
          />
          <Symbol>{Unit}</Symbol>
        </InputContainer>
      )}
      {containsOutput &&
        config.Outputs.map((o) => (
          <React.Fragment key={o.Code}>
            <ExpandContainer>
              <ExpandIcon style={{ margin: '16px 0' }} />
            </ExpandContainer>
            <InputContainer stepState={stepState} style={{ background: 'transparent' }}>
              <InputField
                enabled={enabled}
                value={codeOutput.replace(` ${o.Unit}`, '')}
                onChange={() => undefined}
              />
              <Symbol>{o.Unit}</Symbol>
            </InputContainer>
          </React.Fragment>
        ))}
      {codeError.length > 0 && (
        <div style={{ padding: '10px 20px', color: 'red' }}>{codeError}</div>
      )}
    </Container>
  );
};

export default Input;
