import { useMemo } from 'react';
import { useAtom } from 'jotai';

import { walletAddressAtom } from 'states/mainStore';
import middleEllipsis from 'lib/middleEllipsis';
import useConnected from './useIsConnected';

const useWalletTitle = (defaultTitle: string) => {
  const connected = useConnected();
  const [walletAddress] = useAtom(walletAddressAtom);

  const metamaskTitle = useMemo(() => {
    if (connected) return middleEllipsis(walletAddress);

    return defaultTitle;
  }, [walletAddress]);

  return metamaskTitle;
};

export default useWalletTitle;
