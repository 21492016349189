import isDev from './isDev';

const BASE_URL_DEV = 'https://v.thebifrost.io/oh51dy/RunnersScenario/raw/master';

const BASE_URL_PRODUCTION =
  'https://raw.githubusercontent.com/bifrost-platform/RunnersScenario/master';

const BASE_URL = isDev() ? BASE_URL_DEV : BASE_URL_PRODUCTION;

export default BASE_URL;
