import styled from 'styled-components';

const ScenarioContainer = styled.div<{ isMobile: boolean }>`
  height: calc(100vh - 40px);
  overflow-x: hidden;
  overflow-y: ${({ isMobile }) => (isMobile ? 'scroll' : 'scroll')};
  padding: ${({ isMobile }) => (isMobile ? '20px' : '52px')};
  padding-bottom: ${({ isMobile }) => (isMobile ? '240px' : '0px')};
`;

export default ScenarioContainer;
