import styled from 'styled-components';

const Confirm = styled.div`
  width: 100%;
  height: 48px;
  line-height: 48px;
  border-radius: 3px;
  background-color: #238fc8;
  text-align: center;
  cursor: pointer;
`;

export default Confirm;
