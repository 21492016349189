import React from 'react';
import styled from 'styled-components';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';

import useInvalidKlaytnRpcPopup from 'hooks/useInvalidKlaytnRpcPopup';
import useIsMobile from 'hooks/useIsMobile';
import { selectedCategoryAtom, selectedScenarioAtom } from 'states/mainStore';
import { ReactComponent as SymbolLogo } from 'assets/symbol-logo.svg';

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
`;

const Logo: React.FC = () => {
  const navigate = useNavigate();
  const [, setSelectedScenario] = useAtom(selectedScenarioAtom);
  const [, setSelectedClass] = useAtom(selectedCategoryAtom);
  const [showPopup] = useInvalidKlaytnRpcPopup();

  const isMobile = useIsMobile();

  if (isMobile) return <></>;

  return (
    <Title
      onClick={() => {
        setSelectedScenario(undefined);
        setSelectedClass(undefined);
        navigate('/home');
        // showPopup();
      }}>
      {/* {isMobile && <SymbolLogoSmall style={smallLogoStyle} />} */}
      {!isMobile && <SymbolLogo />}
    </Title>
  );
};

export default Logo;
