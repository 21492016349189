import React from 'react';
import styled, { keyframes } from 'styled-components';

const fx = keyframes`
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }
  100% {
    opacity: 0
  }
`;

const Container = styled.div`
  display: flex;
  width: fit-content;
  padding: 16px 0;
`;

const Dot = styled.span`
  width: 8px;
  height: 8px;
  border: 1px solid #fff;
  background: #fff;
  border-radius: 50%;
  float: left;
  margin: 0 2px;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-animation: ${fx} 1000ms ease infinite 0ms;
  animation: ${fx} 1000ms ease infinite 0ms;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);

  &:nth-child(2) {
    -webkit-animation: ${fx} 1000ms ease infinite 300ms;
    animation: ${fx} 1000ms ease infinite 300ms;
  }

  &:nth-child(3) {
    -webkit-animation: ${fx} 1000ms ease infinite 600ms;
    animation: ${fx} 1000ms ease infinite 600ms;
  }
`;

type LoadingProps = {
  style: React.CSSProperties;
};

const Loading: React.FC<LoadingProps> = ({ style }: LoadingProps) => {
  return (
    <Container style={style}>
      <Dot />
      <Dot />
      <Dot />
    </Container>
  );
};

export default Loading;
