import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useAtom } from 'jotai';

import useIsMobile from 'hooks/useIsMobile';
import StepData from 'types/stepData';
import { selectedScenarioAtom } from 'states/mainStore';
import { currentStepAtom, stepCountAtom } from 'states/scenarioStore';
import StepState from 'types/stepState';
import Color from 'styles/colors';
import useDebounce from 'hooks/useDebounce';
import StepStore from 'states/stepStore';
import MdContainer from 'components/common/mdContainer';
import StepHeader from './stepHeader';
import Description from './description';
import Input from './input';
import WithoutInput from './withoutInput';
import Run from './run';
import Expand from './expand';
import HeaderContainer from './headerContainer';
import HeaderRightContainer from './headerRightContainer';
import Code from './code';
import Output from './output';
import StepStatus from './stepStatus';

const Container = styled.div`
  width: 100%;
  border: 1px solid ${Color.Border};
  border-left: 0;
  border-right: 0;
  padding: 16px 0;
  margin-bottom: 52px;
`;

type StepProps = {
  index: number;
  step: StepData;
};

const Step: React.FC<StepProps> = ({ index, step }: StepProps) => {
  const [selectedScenario] = useAtom(selectedScenarioAtom);
  const [stepCount] = useAtom(stepCountAtom);
  const [currentStep, setCurrentStep] = useAtom(currentStepAtom);
  const [expand, setExpand] = useState(currentStep === index);
  const [stepStore] = useState<StepStore>(new StepStore());
  const [, setStepState] = useAtom(stepStore.stepStateAtom);
  const [, setVerifyCode] = useAtom(stepStore.verifyCodeAtom);
  const [, setCode] = useAtom(stepStore.codeAtom);
  const [, setCodeOutput] = useAtom(stepStore.codeOutputAtom);

  const isMobile = useIsMobile();

  const isFinishStep = useMemo(() => {
    return step.Codes.length === 0 && step.Configs.length === 0;
  }, [step]);

  useEffect(() => {
    setStepState('preparing');
    setCodeOutput('');
  }, []);

  useEffect(() => {
    if (step.Codes.length > 0) {
      const [code] = step.Codes;
      setCode(code);
    }
  });

  useEffect(() => {
    setStepState('preparing');
    setCodeOutput('');
  }, [selectedScenario]);

  useEffect(() => {
    if (isFinishStep) {
      setExpand(currentStep >= index);
    } else {
      setExpand(currentStep === index);
    }

    if (currentStep === index && isFinishStep) {
      setStepState('done');
      setCurrentStep(Math.min(currentStep + 1, stepCount));
    }
  }, [currentStep]);

  return (
    <Container>
      <HeaderContainer>
        <StepHeader isFinishStep={isFinishStep} stepIndex={index + 1} title={step.Title} />
        <HeaderRightContainer>
          {!isMobile && !isFinishStep && (
            <Run stepStore={stepStore} stepIndex={index} step={step} />
          )}
          <StepStatus stepStore={stepStore} />
          <Expand expand={expand} setExpand={setExpand} />
        </HeaderRightContainer>
      </HeaderContainer>
      <div style={{ display: expand ? 'inherit' : 'none' }}>
        <MdContainer markdown={step.Markdown} />
        {step.Configs.map((config) => {
          const containsInput = config.Inputs.length > 0;

          if (containsInput) {
            return (
              <Input
                key={JSON.stringify(config)}
                stepStore={stepStore}
                stepIndex={index}
                config={config}
              />
            );
          }

          return (
            <WithoutInput
              key={JSON.stringify(config)}
              stepStore={stepStore}
              stepIndex={index}
              stepOutput={config.Outputs[0]}
            />
          );
        })}
        {step.Codes.length > 0 && (
          <>
            <Code stepStore={stepStore} />
            <Output stepStore={stepStore} />
          </>
        )}
        {isMobile && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
            <Run stepStore={stepStore} stepIndex={index} step={step} />
          </div>
        )}
      </div>
    </Container>
  );
};

export default Step;
