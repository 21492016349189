import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ExpandIcon } from 'assets/expand.svg';
import Color from 'styles/colors';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 3px;
  background-color: ${Color.Preparing};
`;

const expandStyle: React.CSSProperties = {
  transition: 'all 0.15s ease-out',
  margin: 'auto 0',
  cursor: 'pointer',
};

type ExpandProps = {
  expand: boolean;
  setExpand: React.Dispatch<React.SetStateAction<boolean>>;
};

const Expand: React.FC<ExpandProps> = ({ expand, setExpand }: ExpandProps) => {
  return (
    <Container>
      <ExpandIcon
        onClick={() => setExpand(!expand)}
        style={expand ? expandStyle : { ...expandStyle, transform: 'rotate(180deg)' }}
      />
    </Container>
  );
};

export default Expand;
