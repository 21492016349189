import React from 'react';
import styled from 'styled-components';
import { MEDIA_QUERY_WIDTH_MOBILE_WIDTH } from 'configs/mediaQuery';
import CollapseListItem from './collapseListItem';

export interface ICollapseListItem {
  title?: string;
  contents?: string | React.ReactNode | undefined;
}
interface Props {
  items: ICollapseListItem[];
}

const Root = styled.div`
  margin-top: -36px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    margin-top: -12px;
  }
`;

const CollapseList: React.FC<Props> = ({ items }) => (
  <Root>
    {items.map((item, index) => (
      <CollapseListItem header={item.title} key={item.title}>
        {item.contents}
      </CollapseListItem>
    ))}
  </Root>
);

export default CollapseList;
