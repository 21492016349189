/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import styled from 'styled-components';
import { useAtom } from 'jotai';

import selectedWalletIndexAtom from 'states/connectWalletStore';
import { ReactComponent as QRCode } from 'assets/qr_code_scanner.svg';
import OpenInNew from 'assets/open_in_new.png';

const Container = styled.div`
  display: flex;

  & > * {
    margin-left: 12px;
  }
`;

type InstallWalletProps = {
  index: number;
  url: string;
};

const InstallWallet: React.FC<InstallWalletProps> = ({ index, url }: InstallWalletProps) => {
  const [, setSelectedWalletIndex] = useAtom(selectedWalletIndexAtom);

  return (
    <Container>
      <img
        src={OpenInNew}
        alt=""
        aria-hidden
        style={{ cursor: 'pointer' }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();

          window.open(url, '_blank');
        }}
      />
    </Container>
  );
};

export default InstallWallet;
