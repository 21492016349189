import React from 'react';
import { useAtom } from 'jotai';

import { ReactComponent as DiconnectWalletIcon } from 'assets/disconnect-wallet.svg';
import useIsMobile from 'hooks/useIsMobile';
import {
  networkAtom,
  selectedCategoryAtom,
  selectedScenarioAtom,
  walletAddressAtom,
} from 'states/mainStore';
import useChainrunnerPopup from 'hooks/useChainrunnerPopup';

const buttonStyle: React.CSSProperties = {
  background: '#238fc8',
  borderRadius: '10px',
  padding: '2px',
  width: '26px',
  height: '26px',
};

const mobileButtonStyle: React.CSSProperties = {
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  borderRadius: '12px',
  padding: '7px',
  width: '40px',
  height: '40px',
  margin: '6px 0 6px 6px',
};

type DisconnectWalletProps = {
  onClick: React.MouseEventHandler<SVGSVGElement>;
};

const DisconnectWallet: React.FC<DisconnectWalletProps> = ({ onClick }: DisconnectWalletProps) => {
  const isMobile = useIsMobile();
  const [, setWalletAddress] = useAtom(walletAddressAtom);
  const [, setNetwork] = useAtom(networkAtom);
  const [, setSelectedScenario] = useAtom(selectedScenarioAtom);
  const [, setSelectedClass] = useAtom(selectedCategoryAtom);
  const [, hideNoWalletPopup] = useChainrunnerPopup('popup.noWallet.message');

  const disconnectWalletClick: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setWalletAddress('');
    setNetwork(undefined);
    setSelectedScenario(undefined);
    setSelectedClass(undefined);

    localStorage.removeItem('lastConnectedWalletType');

    hideNoWalletPopup();
  };

  return (
    <DiconnectWalletIcon
      style={isMobile ? mobileButtonStyle : buttonStyle}
      onClick={(e) => {
        disconnectWalletClick(e);
        onClick(e);
      }}
    />
  );
};

export default DisconnectWallet;
