import React, { useMemo, useState, useEffect, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';
import { useAtom } from 'jotai';

import useIsMobile from 'hooks/useIsMobile';
import GA from 'lib/gaService';
import { selectedScenarioAtom, assetsAtom, walletAddressAtom } from 'states/mainStore';
import { currentStepAtom, stepCountAtom } from 'states/scenarioStore';
import invokeMethodAsync from 'lib/invokeMethodAsync';
import StepState from 'types/stepState';
import loadAssets from 'lib/loadAssets';
import StepData from 'types/stepData';
import StepStore from 'states/stepStore';
import { findCoinbase, findMetamask } from 'lib/findProvider';

const Container = styled.div`
  position: relative;
`;

const Title = styled.button<{ enabled: boolean }>`
  background-color: #3299bc;
  border-radius: 3px;
  color: white;
  font-weight: bold;
  font-size: 16px;
  border: 0;
  cursor: pointer;
  pointer-events: ${(props) => (props.enabled ? 'inherit' : 'none')};
  opacity: ${(props) => (props.enabled ? '1' : '0.5')};
  z-index: 1;
  margin: ${(props) => (props.enabled ? '2px' : '0')};
  height: ${(props) => (props.enabled ? '32px' : '36px')};
  min-width: ${(props) => (props.enabled ? '106px' : '110px')};
  max-width: ${(props) => (props.enabled ? '106px' : '110px')};
  position: relative;
`;

const animate = keyframes`
	0% {
		background-position: 0 0;
	}
	50% {
		background-position: 300% 0;
	}
	100% {
		background-position: 0 0;
	}
`;

const Shadow = styled.div`
  --gradient-shadow: linear-gradient(45deg, #3299bc, #ffffff, #3299bc, #ffffff, #3299bc, #ffffff);

  content: '';
  position: absolute;
  background: var(--gradient-shadow);
  background-size: 400%;
  width: 110px;
  height: 36px;
  animation: ${animate} 10s linear infinite;
  filter: blur(24px);
  top: 0px;
`;

type RunProps = {
  stepStore: StepStore;
  stepIndex: number;
  step: StepData;
};

const Run: React.FC<RunProps> = ({ stepStore, stepIndex, step }: RunProps) => {
  const [walletAddress] = useAtom(walletAddressAtom);
  const [stepCount] = useAtom(stepCountAtom);
  const [currentStep, setCurrentStep] = useAtom(currentStepAtom);
  const [selectedScenario] = useAtom(selectedScenarioAtom);
  const [stepState, setStepState] = useAtom(stepStore.stepStateAtom);
  const [verifyCode] = useAtom(stepStore.verifyCodeAtom);
  const [code] = useAtom(stepStore.codeAtom);
  const [, setCodeOutput] = useAtom(stepStore.codeOutputAtom);
  const [codeError, setCodeError] = useAtom(stepStore.codeErrorAtom);
  const [completed, setCompleted] = useState(false);
  const [, setAssets] = useAtom(assetsAtom);
  const [refresh, setRefresh] = useState(0);
  const isMobile = useIsMobile();

  useEffect(() => {
    // setStepState('preparing');
    setCompleted(false);
  }, [selectedScenario]);

  const enabled = useMemo(() => {
    return walletAddress !== '' && stepIndex === currentStep && stepState !== 'loading';
  }, [walletAddress, currentStep, stepState]);

  const evalVerifyCode = async () => {
    const res = await invokeMethodAsync('ToGoEvaluator', 'parser.validateTaster', verifyCode);

    return res;
  };

  const evalCode = useCallback(async () => {
    if (verifyCode.length > 0) {
      const res = await evalVerifyCode();
      if (!res.Ok) {
        setCompleted(false);
        setStepState('error');
        setCodeOutput('');
        setCodeError(res.Error || 'invalid param');
        return;
      }
    }

    if (code === '') return;

    setStepState('loading');
    const res = await invokeMethodAsync<{ Output: string; Log: string }>(
      'ToGoEvaluator',
      'evaluator.runTaster',
      code,
    );

    if (res.Ok) {
      const assets = await loadAssets(selectedScenario?.Metadata.Currency ?? []);
      setAssets(assets);
      setCompleted(true);
      setStepState('done');
      setCodeOutput(res.Output);
      setCodeError('');
    } else {
      setCompleted(false);
      setStepState('error');
      setCodeOutput('');
      setCodeError(res.Error || 'Error');
    }
  }, [code, verifyCode]);

  const chainChanged = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (...[networkId]: unknown[]) => {
      // networkChanged handler context에서는 atom 값을 못 가져온다
      if (currentStep === stepIndex) {
        await invokeMethodAsync('ToGoEvaluator', 'evaluator.updateChain');
        setRefresh(Math.random());
      }
    },
    [code, verifyCode],
  );

  useEffect(() => {
    const { biport } = window;

    const coinbase = findCoinbase();
    const metamask = findMetamask();

    coinbase?.on('chainChanged', chainChanged);
    biport?.on('chainChanged', chainChanged);
    metamask?.on('chainChanged', chainChanged);

    return () => {
      coinbase?.removeListener('chainChanged', chainChanged);
      biport?.removeListener('chainChanged', chainChanged);
      metamask?.removeListener('chainChanged', chainChanged);
    };
  }, []);

  const goNext = useCallback(() => {
    GA.trackSaveAndNextClickEvent({ label: selectedScenario?.Steps?.[currentStep]?.Title });
    setCurrentStep(Math.min(currentStep + 1, stepCount));
  }, [selectedScenario, currentStep]);

  useEffect(() => {
    const [config] = step.Configs;

    if (walletAddress !== '' && config?.Outputs?.length > 0 && stepIndex === currentStep)
      evalCode();
  }, [code, walletAddress, refresh, currentStep]);

  useEffect(() => {
    const [config] = step.Configs;

    const isDynamicInput = config?.Inputs?.length > 0 && config?.Outputs?.length > 0;
    const isWithoutInput = config?.Inputs?.length === 0 && config?.Outputs?.length > 0;

    if (stepState === 'done' && !isDynamicInput && !isWithoutInput) goNext();
  }, [stepState]);

  if (selectedScenario === undefined) return <></>;

  return (
    <>
      {!completed && stepIndex === currentStep && (
        <Container>
          <Title
            enabled={enabled}
            onClick={() => {
              GA.trackRunClickEvent({ label: selectedScenario.Steps[currentStep]?.Title });
              evalCode();
            }}>
            RUN
          </Title>
          {enabled && (
            <>
              <Shadow />
              <Shadow
                style={{
                  filter: 'blur(0px)',
                  borderRadius: '3px',
                }}
              />
            </>
          )}
        </Container>
      )}
      {completed && stepIndex === currentStep && selectedScenario.Steps.length > currentStep && (
        <Container>
          <Title
            enabled={enabled}
            onClick={goNext}
            style={{ fontSize: isMobile ? '13px' : '16px' }}>
            Save &amp; Next
          </Title>
          {enabled && (
            <>
              <Shadow />
              <Shadow
                style={{
                  filter: 'blur(0px)',
                  borderRadius: '3px',
                }}
              />
            </>
          )}
        </Container>
      )}
    </>
  );
};

export default Run;
