import React from 'react';
import styled from 'styled-components';
import { useAtom } from 'jotai';

import { ReactComponent as IdleIcon } from 'assets/idle.svg';
import { ReactComponent as LoadingIcon } from 'assets/loading.svg';
import { ReactComponent as DoneIcon } from 'assets/done.svg';
import { ReactComponent as ErrorIcon } from 'assets/error.svg';
import Loading from 'components/common/loading';
import Color from 'styles/colors';
import StepState from 'types/stepState';
import { selectedScenarioAtom } from 'states/mainStore';
import { currentStepAtom } from 'states/scenarioStore';
import StepStore from 'states/stepStore';
import { useEffect } from 'react';

const backgroundColorByStatus = (status: StepState) => {
  switch (status) {
    case 'done':
      return '#1fbf8f';
    case 'loading':
      return Color.Default;
    case 'preparing':
      return Color.Preparing;
    case 'error':
      return 'red';
    default:
      return Color.Preparing;
  }
};

const iconByStatus = (status: StepState) => {
  switch (status) {
    case 'loading':
      return <Loading style={{}} />;
    case 'done':
      return <DoneIcon />;
    case 'error':
      return <ErrorIcon />;
    case 'preparing':
    default:
      return <IdleIcon />;
  }
};

// TODO: Props drilling 문제 React.Context로 변경하여 해결 필요
type ContainerProps = {
  status: StepState;
};

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  padding: 8px;
  border-radius: 3px;
  background-color: ${(props) => backgroundColorByStatus(props.status)};
`;

type StepStatusProps = {
  stepStore: StepStore;
};

const StepStatus: React.FC<StepStatusProps> = ({ stepStore }: StepStatusProps) => {
  const [selectedScenario] = useAtom(selectedScenarioAtom);
  const [currentStep] = useAtom(currentStepAtom);
  const [stepState] = useAtom(stepStore.stepStateAtom);

  if (selectedScenario === undefined) return <></>;

  return (
    <Container id={selectedScenario.Title} key={selectedScenario.Title} status={stepState}>
      {iconByStatus(stepState)}
    </Container>
  );
};

export default StepStatus;
