import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';

import Category from 'types/menu';
import { languageAtom, networkAtom, networkMetasAtom } from 'states/mainStore';
import BASE_URL from 'lib/baseUrl';

const useMenus = () => {
  const [language] = useAtom(languageAtom);
  const [network] = useAtom(networkAtom);
  const [networkMetas] = useAtom(networkMetasAtom);
  const [menus, setMenus] = useState<Category[]>([]);

  useEffect(() => {
    (async () => {
      const networkMeta = networkMetas.find((n) => n.ChainID === network?.chainId);

      let fileName = '';
      switch (language.toUpperCase()) {
        case 'EN':
          fileName = networkMeta?.['Scenarios-EN'] ?? '';
          break;
        case 'KR':
          fileName = networkMeta?.['Scenarios-KR'] ?? '';
          break;
        default:
          break;
      }
      if (!fileName) return;

      const menusJson = await (await fetch(`${BASE_URL}/${fileName}`)).json();

      setMenus(menusJson as Category[]);
    })();
  }, [network, language, networkMetas]);

  return menus;
};

export default useMenus;
