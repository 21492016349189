import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Atom, SetStateAction, useAtom } from 'jotai';

import { categoriesAtom, selectedCategoryAtom } from 'states/mainStore';
import Category from 'types/menu';
import HasChildMenu from 'types/hasChildMenu';
import useOnClickOutside from 'hooks/useOnClickOutside';
import OptionScenario from './optionScenario';
import {
  DropDownContainer,
  DropDownLabel,
  DropDownLabelPlaceHolder,
  DropDownList,
  DropDownOption,
} from './dropDown';

type DropdownScenarioProps = {
  target: HasChildMenu | undefined;
  setTarget: (update?: SetStateAction<HasChildMenu | undefined>) => void;
  subList: HasChildMenu[];
  placeholderItem: HasChildMenu | undefined;
};

const DropdownScenario: React.FC<DropdownScenarioProps> = ({
  target,
  setTarget,
  subList,
  placeholderItem,
}: DropdownScenarioProps) => {
  const ref = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);

  useOnClickOutside(ref, () => setIsExpanded(false));

  return (
    <DropDownContainer>
      <DropDownLabel ref={ref} expand={isExpanded} onClick={() => setIsExpanded(!isExpanded)}>
        {target && <OptionScenario title={target?.Class ?? ''} symbol={target?.Symbol ?? ''} />}
        {!target && (
          <div style={{ opacity: 0.5 }}>
            <OptionScenario
              title={placeholderItem?.Class ?? ''}
              symbol={placeholderItem?.Symbol ?? ''}
            />
          </div>
        )}
      </DropDownLabel>
      <DropDownList expand={isExpanded} style={{ zIndex: 3, backgroundColor: '#53626b' }}>
        {subList.map((menu) => {
          return (
            <DropDownOption
              key={JSON.stringify(menu)}
              expand={isExpanded}
              onClick={() => {
                setIsExpanded(false);
                setTarget(menu);
              }}>
              <OptionScenario title={menu.Class} symbol={menu.Symbol ?? ''} />
            </DropDownOption>
          );
        })}
      </DropDownList>
    </DropDownContainer>
  );
};

export default DropdownScenario;
