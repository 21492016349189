const resource = {
  translation: {
    popup: {
      reset: {
        message:
          '연결된 지갑의 주소 혹은 네트워크 변경으로 인해 현재까지 진행된 과정이 초기화 되었습니다',
      },
    },
    landing: {
      title: '복잡한 디파이, 한번에 해결하세요.',
      subtitle: '너무 많아서 어렵고 번거로운 디파이. 이제 ChainRunner Q로 한번에 처리하세요.',
      about: 'ChainRunner란?',
      popularScenarios: {
        title: 'Popular Scenarios',
        subtitle: 'ChainRunner Q의 강력한 기능을 활용해보세요.',
        migration: 'Migration',
        bifiX: 'BiFi X',
        tokenPurchase: 'Token Purchase',
      },
      pros: {
        pros1: '여러 디파이, 스마트 컨트랙트를 한곳에.',
        pros2: '복잡하고 번거로운 서비스들을 한번에.',
        pros3: '단순한 계산기부터 강력한 컨트렉트 테스트까지.',
      },
      faq: {
        title: 'FAQ',
        q1: 'Chainrunner가 무엇인가요?',
        a1: `Chainrunner의 시작은 근본적으로 Bifrost의 smart contract를 손쉽게 설치하고 사용하고
        테스트하기 위함이었습니다. 이 서비스는 개발자 뿐만 아니라 모두를 위한 디파이 활용
        서비스인 Chainrunner Q로 시작하여, 추후에는 강력한 smart contract 테스트 기능까지 지원할
        것입니다. Chainrunner Q는 여러 서비스와 연결되어, 사용자에게 받은 명령을 처리하는
        서비스입니다. Defi의 자산을 다른 서비스로 한번에 옮길 수 있고, 레버리지, 유동성 페어
        투자, 구매 등의 복잡한 과정을 이곳에서 간단하게 해결할 수 있습니다.`,
        q2: '왜 Chainrunner Q를 사용해야 할까요?',
        a2: `그동안 디파이가 너무 어렵고 복잡하여 시도해보지도 못했다면, Chainrunner Q를
        사용해보시는걸 추천드립니다. 블록체인 생태계에서 디파이, 메인넷 등의 종류는 너무 많고,
        복잡하고, 배워야 할 절차들이 너무 많습니다. 나의 자산을 디파이끼리 옮기는 과정만 해도 각
        디파이의 사용법을 일일이 익혀야 하는 상황이죠. Chainrunner Q에서는 다양한 디파이에서
        발생하는 다양한 서비스 시나리오가 한번에 제공되기 때문에, 정해진 메뉴얼대로 거래에
        사용될 가상자산의 수량만 입력하면 한번에 다양한 거래를 처리할 수 있습니다.`,
        q3: 'Chainrunner Q를 사용하면 가스비를 절감할 수 있을까요?',
        a3: `Chainrunner Q는 여러 서비스와 연결되어 이용자들에게 편의를 제공하고 있습니다. 분산되고
        복잡한 여러 블록체인 서비스들과 한번에 연결해주는 기능을 하고 있기 때문에, 고유 네트워크
        상에서 발생하는 수수료까지는 관여할 수 없습니다. 현재로서 가스비 절약을 위해선 이더리움
        네트워크 대비 92%가량 저렴한 가스비를 제공하는 BSC 네트워크에서 서비스를 이용하시는것을
        추천드립니다.`,
        q4: 'Chainrunner Q에서는 어떤 디파이가 지원되고 있나요?',
        a4: `현재까지 Compound, AAVE, BiFi, BiFi X, Sushiswap 서비스를 활용하는 다양한 시나리오가
        제공되고 있습니다. 최종적으로는 블록체인상의 모든 플랫폼을 다양한 방법으로 활용할 수
        있도록, 더 많은 디파이 플랫폼과 다양한 시나리오를 점차적으로 추가해나갈 것입니다.`,
        q5: '이용 수수료가 발생하나요?',
        a5: `Chainrunner Q로 시작하는 서비스에는 현재 이용 수수료를 부과하고 있지 않습니다. 추후,
        다양한 서비스와 시나리오가 확장됨에 따라 BFC 수수료 지불 정책을 적용하여 생태계를 확장해
        나갈 예정입니다.`,
      },
    },
  },
};

export default resource;
