const defaultLanguage = () => {
  const browserLanguage = navigator.language;
  const storedLanguage = localStorage.getItem('language') || '';

  let currentLanguage = storedLanguage || browserLanguage || 'en';

  if (currentLanguage.toLowerCase().startsWith('ko')) {
    currentLanguage = 'kr';
  }

  if (currentLanguage.toLowerCase().startsWith('en')) {
    currentLanguage = 'en';
  }

  return currentLanguage;
};

export default defaultLanguage;
