/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useAtom } from 'jotai';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';

import useIsMobile from 'hooks/useIsMobile';
import {
  showTabAtom,
  selectedScenarioAtom,
  selectedCategoryAtom,
  networkAtom,
} from 'states/mainStore';
import useInvalidKlaytnRpcPopup from 'hooks/useInvalidKlaytnRpcPopup';
import { currentStepAtom, stepCountAtom } from 'states/scenarioStore';
import { findCoinbase, findMetamask } from 'lib/findProvider';
import invokeMethodAsync from 'lib/invokeMethodAsync';
import Color from 'styles/colors';
import { getNetworkInfoByChainId } from 'lib/getNetworkInfo';
import useChainrunnerPopup from 'hooks/useChainrunnerPopup';
import Tabs from './tabs';
import WalletStatus from './walletStatus';
import Landing from './landing/landing';
import About from './about/about';
import ShowAllMenus from './showAllMenus';
import ScenarioPage from './scenario';
import SelectScenarioDetail from './selectScenarioDetail';
import Dex from './dex/dex';

const Container = styled.div<{ isMobile: boolean }>`
  min-width: ${(props) => (props.isMobile ? '100vw' : '1344px')};
  width: 100vw;
  height: 100vh;
  background-color: ${Color.Background};
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  justify-content: ${(props) => (props.isMobile ? 'inherit' : 'center')};
`;

const SideBarContainer = styled.div<{ isMobile: boolean }>`
  min-width: ${(props) => (props.isMobile ? '100vw' : '252px')};
  width: ${(props) => (props.isMobile ? '100vw' : '15vw')};
  height: 100vh;
  border: solid 1px ${Color.Border};
  border-top: 0;
  border-bottom: 0;
  display: flex;
  flex-direction: column;
`;

const RightContainer = styled.div<{ isMobile: boolean }>`
  min-width: ${(props) => (props.isMobile ? '100vw' : '1092px')};
  width: ${(props) => (props.isMobile ? '100vw' : '65vw')};
  height: ${(props) => (props.isMobile ? 'inherit' : '100%')};
  flex-grow: ${(props) => (props.isMobile ? '1' : 'inherit')};
`;

const Main: React.FC = () => {
  const navigate = useNavigate();
  const [showTab, setShowTab] = useAtom(showTabAtom);
  const [network, setNetwork] = useAtom(networkAtom);
  const [selectedScenario, setSelectedScenario] = useAtom(selectedScenarioAtom);
  const [selectedClass, setSelectedClass] = useAtom(selectedCategoryAtom);
  const [, setStepCount] = useAtom(stepCountAtom);
  const [, setCurrentStep] = useAtom(currentStepAtom);
  const [showRpcPopup] = useInvalidKlaytnRpcPopup();
  const location = useLocation();

  // media query
  const isMobile = useIsMobile();

  useEffect(() => {
    if (selectedScenario !== undefined) setStepCount(selectedScenario.Steps.length);
    setCurrentStep(0);
  }, [selectedScenario]);

  useEffect(() => {
    if (!isMobile) setShowTab(true);
  }, [isMobile]);

  const [showPopup] = useChainrunnerPopup('popup.reset.message');

  const resetScenario = () => {
    if (selectedScenario !== undefined) {
      showPopup();
    }

    const file = localStorage.getItem('file') ?? '';
    const category = localStorage.getItem('category') ?? '';

    if (file && category) {
      localStorage.removeItem('file');
      localStorage.removeItem('category');
    } else {
      setSelectedClass(undefined);
      setSelectedScenario(undefined);
      navigate('/home');
    }
  };

  useEffect(() => {
    const { biport } = window;

    const coinbase = findCoinbase();
    const metamask = findMetamask();

    coinbase?.on('accountsChanged', accountChanged);
    biport?.on('accountsChanged', accountChanged);
    metamask?.on('accountsChanged', accountChanged);

    coinbase?.on('chainChanged', chainChanged);
    biport?.on('chainChanged', chainChanged);
    metamask?.on('chainChanged', chainChanged);

    if (window.location.pathname === '/') {
      navigate('/home');
    }

    return () => {
      coinbase?.removeListener('accountsChanged', accountChanged);
      biport?.removeListener('accountsChanged', accountChanged);
      metamask?.removeListener('accountsChanged', accountChanged);

      coinbase?.removeListener('chainChanged', chainChanged);
      biport?.removeListener('chainChanged', chainChanged);
      metamask?.removeListener('chainChanged', chainChanged);
    };
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const accountChanged = useCallback((...[accounts]: unknown[]) => {
    resetScenario();
  }, []);

  const chainChanged = useCallback(
    async (...[chainId]: unknown[]) => {
      const res = await invokeMethodAsync<{ Network: number }>(
        'ToGoEvaluator',
        'evaluator.updateChain',
      );
      if (!res.Ok) {
        setNetwork(undefined);
      }

      const newNetwork = getNetworkInfoByChainId(res.Network);
      setNetwork(newNetwork);

      if (network?.chainId === 8217) {
        const cypressRes = await invokeMethodAsync(
          'ToGoEvaluator',
          'evaluator.isCypressNodeAvailable',
        );
        if (!cypressRes.Ok) {
          showRpcPopup();
        }
      }

      if (window.location.pathname !== '/dex') {
        resetScenario();
      }
    },
    [network],
  );

  return (
    <Container isMobile={isMobile}>
      <SideBarContainer isMobile={isMobile}>
        <WalletStatus />
        {showTab && <Tabs />}
        {showTab && <ShowAllMenus />}
      </SideBarContainer>
      <RightContainer isMobile={isMobile}>
        <Routes>
          <Route path=":fileName" element={<ScenarioPage />} />
          <Route
            path="home"
            element={
              <>
                {!selectedClass && <Landing />}
                {selectedClass && <SelectScenarioDetail />}
              </>
            }
          />
          <Route path="about" element={<About />} />
          {/* <Route path="dex" element={<Dex />} /> */}
        </Routes>
      </RightContainer>
    </Container>
  );
};

export default Main;
