import styled from 'styled-components';

const AddressContainer = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? 'initial' : '100%')};
  height: ${({ isMobile }) => (isMobile ? '40px' : 'initial')};
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.25);
  display: flex;
  justify-content: space-between;
  line-height: ${({ isMobile }) => (isMobile ? '52px' : '36px')};
  margin: ${({ isMobile }) => (isMobile ? '6px 6px 6px 0' : '8px 0 0 0')};
  cursor: pointer;
  align-items: center;
  padding: 0px 6px;
  padding-right: ${({ isMobile }) => (isMobile ? '0px' : '6px')};
`;

export default AddressContainer;
